import React, { Component } from 'react';
import { Wrapper } from './styles';

class Contents extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <Wrapper>
        <ul>
          <li>
            1. Write down your password & key!  legacy.ptrader.co is a NON custodial service.
            <p>Meaning pTrader can NOT recover your password or key if you lose or forget them.</p>
          </li>
          <li>
            <p>2. Only Send Factom Address type pAssets or PEG to your pTrader account (Ethereum / ERC20 pAssets can't do conversions).</p>
          </li>
          <li>
            3. Conversions from PEG to pAssets are unlimited, however conversions from pAssets to PEG are very limited.
            <p>See article here: <a href="https://medium.com/pegnet/predictable-supply-of-peg-3fd39124f36" target="_blank">https://medium.com/pegnet/predictable-supply-of-peg-3fd39124f36</a></p>
          </li>
          <li><p>4. For support go to: <a href="https://discord.gg/XkWYXPW" target="_blank">https://discord.gg/XkWYXPW</a></p></li>
          <li>5. pTrader is in Beta and intended as a demo. Download it and run the code locally. pTrader is a NON hosted service</li>
        </ul>
      </Wrapper>
    )
  }
}

export default Contents;
