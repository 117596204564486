import React from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from "@material-ui/core/styles";

import { Wrapper, Label, LabelEst } from './styles';
import { STORE_KEYS } from '@/stores';
import { commafyDigitFormat } from "@/utils";
import { pAssetCoins } from "@/stores/CoinsHandleStore";

const useStyles = makeStyles({
  input: {
    width: '100%',
    marginBottom: '15px',
  },
  select: {
    width: '100%',
    border: '1px solid #ccc',
    height: 50,
    borderRadius: 5,
    textAlign: 'left',
    paddingLeft: 13,
  }
});

function Conversion(props) {
  const { myBalances, symbol, getCoinPrice } = props;
  const classes = useStyles();
  const [amount, setAmount] = React.useState(0);
  const [estAmount, setEstAmount] = React.useState('N/A');
  const [to, setTo] = React.useState('');
  const balance = (myBalances[symbol] || 0) / 100000000;

  const handleChangeAmount = event => {
    setAmount(event.target.value);

    const rate01 = getCoinPrice(symbol);
    const rate02 = getCoinPrice(to);
    if (rate01 === 0 || rate02 === 0) {
      setEstAmount('N/A');
    } else {
      const rate = rate01 / rate02;
      setEstAmount((rate * event.target.value).toFixed(8));
    }
  };

  const handleChangeTo = event => {
    setTo(event.target.value);

    const rate01 = getCoinPrice(symbol);
    const rate02 = getCoinPrice(event.target.value);
    if (rate01 === 0 || rate02 === 0) {
      setEstAmount('N/A');
    } else {
      const rate = rate01 / rate02;
      setEstAmount((rate * amount).toFixed(8));
    }
  };

  const handleConvertSubmit = () => {
    const { convertCoin, symbol } = props;
    convertCoin(amount, symbol, to);
  };

  return (
    <Wrapper>
      <div>
        <Label>Convert From:</Label>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            disabled: true,
          }}
          variant="outlined"
          value={symbol}
          className={classes.input}
        />
        <Label>Amount ( {symbol} ):</Label>
        <TextField
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className={classes.input}
          value={amount}
          onChange={handleChangeAmount}
        />
        <Label>Convert To:</Label>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={to}
          onChange={handleChangeTo}
          className={classes.select}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {
            pAssetCoins.map((item, index) => {
              if (symbol !== 'PEG' || item.symbol !== 'pFCT') {
                return (
                  <MenuItem value={item.symbol} key={index}>{item.symbol} - {item.name}</MenuItem>
                );
              }
            })
          }
        </Select>
        {
          to && (
            <LabelEst><span>Estimated</span> Amount: {estAmount}</LabelEst>
          )
        }
        <div className="btn_wrapper">
          <button className="btn_send" onClick={handleConvertSubmit}>Convert</button>
        </div>
      </div>
      <span className="warning">balance: {commafyDigitFormat(balance, 8)} {symbol}</span>
    </Wrapper>
  );
}

export default compose(
  inject(STORE_KEYS.REALDEPOSITSTORE, STORE_KEYS.COINPRICESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.REALDEPOSITSTORE]: {
         myBalances, convertCoin,
       },
       [STORE_KEYS.COINPRICESTORE]: {
         getCoinPrice,
       },
     }) => ({
      myBalances,
      convertCoin,
      getCoinPrice,
    })
  )
)(Conversion);
