import React from 'react';

export function convertHexToRgba(hex, alpha = 1) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = `0x${c.join('')}`;
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`;
    }
    throw new Error(`Bad Hex ${hex}`);
}

// Show messages
export const showMessage = (func, msg, type) => {
  const duration = (type === 'success' || type === 'info') ? 2000 : 4000;

  func(msg, {
    variant: type,
    autoHideDuration: duration,
  });
};

// Hex to RGB color
export const hexToRgb = (inp) => {
  let input = `${inp}`;
  input = input.replace('#', '');
  const hexRegex = /[0-9A-Fa-f]/g;

  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error('input is not a valid hex color.');
  }

  if (input.length === 3) {
    const first = input[0];
    const second = input[1];
    const last = input[2];
    input = first + first + second + second + last + last;
  }

  input = input.toUpperCase(input);

  const first = input[0] + input[1];
  const second = input[2] + input[3];
  const last = input[4] + input[5];

  return `${parseInt(first, 16)}, ${parseInt(second, 16)}, ${parseInt(last, 16)}`;
};

// Parse phone number string
export const parsePhoneNumber = (string) => string.replace(/[ ()-]/g, '');

// Check two date is same
export const isSameDay = (date1, date2) => (
  date1.getUTCDate() === date2.getUTCDate()
  && date1.getUTCMonth() === date2.getUTCMonth()
  && date1.getUTCFullYear() === date2.getUTCFullYear()
);

// Filterer by whitelabel
export const uFilterer = (u, whitelabel) => (u.whitelabel === whitelabel) || (whitelabel === 'All');
export const dwFilterer = (d, users) => users.findIndex((u) => d.phone === u.phone) > -1;

// Reducer to sum of amount and balance
export const reducer = (total, current) => total + current.amount;
export const bReducer = (total, current) => total + current.balance;

export const commafyDigitFormat = (num, digitLength) => {
  return num;
};

export const customDigitFormatWithNoTrim = (input, count = 6, decimalCounts) => {
  let num = input;
  if (num < 0) return '';
  if (typeof input === 'string') {
    num = input.replace(/,/g, '');
    if (!Number.parseFloat(num)) {
      num = 0;
    } else {
      num = parseFloat(num);
    }
  }
  num = num.toFixed(count);

  const digitLength = parseInt(num).toString().length;

  if (digitLength > 9) {
    return `${formatTotalDigitString(num / 1000000000, count - 1)}B`;
  }
  if (digitLength > 6) {
    return `${formatTotalDigitString(num / 1000000, count - 1)}M`;
  }
  if (digitLength > 5) {
    return `${formatTotalDigitString(num / 1000, count - 1)}K`;
  }

  const decimals = decimalCounts || Math.max(0, count - digitLength);

  return parseFloat(num).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
};

/**
 * Format fiat value with 2 decimals
 *
 * ex:
 * input 1.2345 -> output 1.23
 * input 0.0012 -> output 1.2m
 * input 0.0000012 -> output 1.2µ
 * input 0.0000000012 -> output 1.2n
 *
 * @param {String} input
 * @param {Number} digits
 * @param {Boolean} useGrouping
 * @return {Object}
 */
export const formatFiatString = (input, digits = 2, useGrouping = true) => {
  let inputValue = input;
  let unitPrefix = '';

  if (input > 0.01) {
    inputValue = input;
  } else if (input > 0.00001) {
    inputValue = input * 1000;
    unitPrefix = 'm';
  } else if (input > 0.00000001) {
    inputValue = input * 1000000;
    unitPrefix = 'µ';
  } else if (input > 0.00000000001) {
    inputValue = input * 1000000000;
    unitPrefix = 'n';
  } else {
    inputValue = 0;
    unitPrefix = '';
  }

  const unitValue =
    inputValue === 0
      ? 0
      : parseFloat(inputValue).toLocaleString('en-US', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
        useGrouping
      });
  return {
    unitValue,
    unitPrefix
  };
};

export const formatCoinString = (string, num) =>
  parseFloat(string).toLocaleString('en-US', {
    minimumFractionDigits: num,
    maximumFractionDigits: num
  });

export const customDigitFormat = (input, count = 6) => {
  let num = input;
  if (typeof input === 'string') {
    num = input.replace(/,/g, '');
    if (!Number.parseFloat(num)) {
      num = 0;
    }
  }

  const digitLength = parseInt(num).toString().length;

  if (digitLength > 9) {
    return `${formatTotalDigitString(num / 1000000000, count - 1)}B`;
  }
  if (digitLength > 6) {
    return `${formatTotalDigitString(num / 1000000, count - 1)}M`;
  }
  if (digitLength > 5) {
    return `${formatTotalDigitString(num / 1000, count - 1)}K`;
  }

  let decimals = count - digitLength;
  if (decimals < 0) {
    decimals = 0;
  }

  return trimTrailingZero(
    parseFloat(num).toLocaleString('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    }),
    input
  );
};

// If input has trailing zeros, cut them as long as original does not have more decimals
// ex: input: 1.0000, original: 1.0000001, then do not cut
// input: 1.0000, original: 0.9999, then cut .0000
// input: 1.0000, original: 1, then cut .0000
export const trimTrailingZero = (input, original) => {
  // Get original decimal length
  let numOriginal = original;
  if (typeof numOriginal === 'string') {
    numOriginal = numOriginal.replace(/,/g, '');
    numOriginal = Number.parseFloat(numOriginal) || 0;
  }
  const decimalOriginal = numOriginal - Number.parseInt(numOriginal);

  let numInput = input;
  if (typeof numInput === 'string') {
    numInput = numInput.replace(/,/g, '');
    numInput = Number.parseFloat(numInput) || 0;
  }
  const decimalInput = numInput - Number.parseInt(numInput);

  if (decimalOriginal - decimalInput !== 0 && Number.parseInt(numOriginal) === Number.parseInt(numInput)) {
    return input;
  }

  // Get input decimal position
  const strInput = String(input);
  const decimalPosition = strInput.lastIndexOf('.');

  // If there is no decimal just return
  if (decimalPosition === -1) {
    return input;
  }

  // else cut 0s
  let i = strInput.length - 1;
  while (i >= 0 && strInput[i] === '0' && i > decimalPosition) {
    i--;
  }

  if (strInput[i] === '.') {
    i--;
  }

  return strInput.substr(0, i + 1);
};

export const formatTotalDigitString = (input, count) => {
  let num = input;
  if (typeof input === 'string') {
    num = input.replace(/,/g, '');
    if (!Number.parseFloat(num)) {
      num = 0;
    }
  }

  let decimals = count - parseInt(num).toString().length;
  if (decimals < 0) {
    decimals = 0;
  }

  return trimTrailingZero(
    parseFloat(num).toLocaleString('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    }),
    input
  );
};
