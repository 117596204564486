import React from "react";
import ReactPlayer from "react-player";

import { Wrapper, Header, Content, Logo, Button } from '../styles';

export default () => {
  return (
    <Wrapper>
      <Header>
        <Logo src={'/assets/img/logo.png'}/>
        <Button href={'/login'}>Login</Button>
        <Button href={'/register'}>Register</Button>
      </Header>
      <Content>
        <ReactPlayer
          url='https://youtu.be/UcR6pojGDyo'
          controls
          width='50%'
          height='60%'
        />
      </Content>
    </Wrapper>
  );
}
