import styled from 'styled-components';
import QRCode from 'qrcode.react';

export const Wrapper = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
  > h6 {
    font-weight: bold;
    word-break: break-all;
    text-align: center;
  }
  
  > p {
    display: flex;
    align-items: center;
  }
  
  .symbol {
    color: #f00;
    display: flex;
    align-items: center;
    padding: 0 5px;
  }
  
  .warning {
    position: absolute;
    top: 70px;
    right: 16px;
    font-size: 15px;
    font-weight: bold;
  }
  
  .recommend {
    max-width: 500px;
    text-align: center;
    line-height: 2;
    color: #b00;
    font-size: 19px;
  }
  
  button {
    align-items:flex-start;
    border-radius:4px;
    box-shadow:rgb(221, 225, 227) 0px 1px 2px 0px;
    box-sizing:border-box;
    color:rgb(255, 255, 255);
    cursor:pointer;
    direction:ltr;
    display:inline-block;
    font-size:15px;
    font-stretch:100%;
    font-style:normal;
    font-variant-caps:normal;
    font-variant-east-asian:normal;
    font-variant-ligatures:normal;
    font-variant-numeric:normal;
    font-weight:800;
    width: 150px;
    height: 40px;
    letter-spacing:normal;
    line-height:22.5px;
    margin-bottom:0px;
    margin-left:0px;
    margin-right:0px;
    margin-top:0px;
    overflow-wrap:break-word;
    overflow-x:visible;
    overflow-y:visible;
    padding-bottom:6px;
    padding-left:12px;
    padding-right:12px;
    padding-top:6px;
    text-align:center;
    text-indent:0px;
    text-rendering:auto;
    text-shadow:none;
    text-size-adjust:100%;
    text-transform:none;
    transition-delay:0s, 0s, 0s, 0s;
    transition-duration:0.15s, 0.15s, 0.15s, 0.15s;
    transition-property:color, background-color, border-color, box-shadow;
    transition-timing-function:ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    user-select:none;
    vertical-align:middle;
    word-spacing:0px;
    writing-mode:horizontal-tb;
  }
  
  .btn_enable {
    background-color: #3ebb9e;  
    margin-right: 20px;
    box-shadow: none;
  }
  
  .btn_dismiss {
    background-color: #edf0f2;  
    color: #333;
  }
`;

export const Code = styled(QRCode).attrs({
  size: '70%',
  level: 'L',
  bgColor: 'white',
  includeMargin: true,
  renderAs: 'svg'
})``;

export const Icon = styled.div`
  width: 25px;
  height: 25px;
  background: ${props => `url('/assets/img/icons_pegcoin/${props.name}')`} no-repeat;
  background-size: 100% 100%;
`;
