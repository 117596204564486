import React from "react";
import Section1 from './section1';

export default () => {
  return (
      <>
        <Section1 />
      </>
  );
};
