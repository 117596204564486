import React, { Component, createRef } from "react";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import { Wrapper, Button, InlineInput, SplitInputItem } from './styles';
import { Redirect } from "react-router-dom";
import InputConfirm from "../../../components/InputConfirm";

class ConfirmEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
      error: '',
    };
    this.wrapperRefs = [createRef(), createRef(), createRef(), createRef(), createRef(), createRef()];
  }

  onClickConfirmCode = () => {
    const { confirmMail, pendingEmail } = this.props;
    let token = '';
    this.wrapperRefs.map((item, index) => {
      token = token + '' + item.current.value;
    });

    confirmMail(pendingEmail, token)
      .then(() => {
        this.setRedirect();
      })
      .catch(e => {
        this.setState({
          isValid: false,
          error: 'Sorry, your confirmation is failed.'
        });
      });
  };

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/home' />
    }
  };

  render() {
    const { pendingEmail } = this.props;

    return (
      <Wrapper>
        {this.renderRedirect()}
        <h1 className="very_small_bottom_margin">Check your email!</h1>
        <p className="desc2">
          We've sent a 6-digit confirmation code to {pendingEmail}. It will expire shortly, so enter it soon.
        </p>

        <div className="confirmation_code">
          <InputConfirm wrapperRefs={this.wrapperRefs} />
        </div>
        <p className="notify_text">
          Keep this window open while checking for your code. Remember to try your spam folder!
        </p>
        <Button onClick={this.onClickConfirmCode}>Confirm</Button>
      </Wrapper>
    );
  }
}

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: { pendingEmail, confirmMail },
     }) => ({
      pendingEmail,
      confirmMail,
    })
  )
)(ConfirmEmail);
