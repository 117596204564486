import React from "react";
import { observable, action, reaction } from 'mobx';
import {
  GetSecurityRequest, Verify2FARequest, Enable2FARequest, Disable2FARequest, ExportAccountRequest, VerifyPassRequest
} from "@/lib/ws";

class SecurityStore {
  @observable isTry2fa = false;
  @observable is_tfa = false;
  @observable tfaStr = '';
  @observable publicAddr = '';
  @observable secret = '';
  @observable exportStep = 'INIT';
  isLoggedIn = false;

  constructor(authStore, snackbar) {
    this.snackbar = snackbar;
    this.isLoggedIn = authStore.isLoggedIn;

    if (this.isLoggedIn) {
      this.getSecurityRequest();
    }

    reaction(
      () => ({
        isLoggedIn: authStore.isLoggedIn
      }),
      authObj => {
        this.isLoggedIn = authObj.isLoggedIn;
        console.log("authObj.isLoggedIn:", authObj.isLoggedIn);
        if (this.isLoggedIn) {
          this.getSecurityRequest();
        }
      }
    );
  }

  @action.bound handleChangeSwitch() {
    this.isTry2fa = !this.isTry2fa;
    if (this.isTry2fa) {
      this.enable2FARequest();
    }
  }

  @action.bound getSecurityRequest() {
    GetSecurityRequest()
      .then(data => {
        this.is_tfa = data.is_tfa || false;
      })
      .catch(() => {
        this.is_tfa = false;
      });
  }

  @action.bound exportAccount(tfaCode) {
    ExportAccountRequest({ tfaCode })
      .then(data => {
        if (!data.error) {
          this.exportStep = 'COMPLETED';
          this.publicAddr = data.publicA || '';
          this.secret = data.privateA || '';
          this.showSnackMsg(data.message);
        } else {
          this.showSnackMsg( data.error);
        }
      });
  }

  @action.bound resetKeys() {
    this.exportStep = 'INIT';
    this.publicAddr = '';
    this.secret = '';
  }

  @action.bound verifyPass(pass) {
    VerifyPassRequest({ pass })
      .then(data => {
        if (!data.error) {
          this.exportStep = 'CONFIRM';
        } else {
          this.showSnackMsg( data.error);
        }
      });
  }

  @action.bound verify2FARequest(code) {
    Verify2FARequest({ code })
      .then(data => {
        if (!data.error) {
          this.showSnackMsg(data.message);
          this.is_tfa = true;
        } else {
          this.showSnackMsg( data.error);
        }
      });
  }

  @action.bound enable2FARequest() {
    Enable2FARequest()
      .then(data => {
        if (!data.error) {
          this.tfaStr = data.dataUrl || '';
        } else {
          this.showSnackMsg( data.error);
        }
      });
  }

  @action.bound disable2FARequest() {
    Disable2FARequest()
      .then(data => {
        if (!data.error) {
          this.showSnackMsg(data.message);
        } else {
          this.showSnackMsg( data.error);
        }
      });
  }

  /**
   *  Snackbar Popup message
   */
  @action.bound showSnackMsg(msg) {
    this.snackbar({
      message: () => (
        <>
          <span>
            <b>{msg}</b>
          </span>
        </>
      )
    });
  }
}

export default (authStore, snackbar) => new SecurityStore(authStore, snackbar);
