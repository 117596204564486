import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';

import FrameBox from '@/components/FrameBox';
import { STORE_KEYS } from '@/stores';

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password01: '',
      password02: '',
      tfaCode: '',
    };
  }

  onUpdatePassword = () => {
    const token = this.props.match.params.token;
    const {
      password01, password02, tfaCode,
    } = this.state;
    const {
      showSnackMsg, resetPassword,
    } = this.props;

    if ((password01 || '').trim().length === 0) {
      showSnackMsg('Please input password correctly.');
      return;
    }
    if (password01 !== password02) {
      showSnackMsg('Passwords do not match.');
      return;
    }
    if ((tfaCode || '').trim().length === 0) {
      showSnackMsg('Please input 2 factor code correctly.');
      return;
    }

    resetPassword(token, password01, tfaCode);
  };

  onChangePass01 = (e) => {
    this.setState({
      password01: e.target.value || '',
    });
  };

  onChangePass02 = (e) => {
    this.setState({
      password02: e.target.value || '',
    });
  };

  onChangeTfaCode = (e) => {
    this.setState({
      tfaCode: e.target.value || '',
    });
  };

  render() {
    const {
      password01, password02, tfaCode, error,
    } = this.state;

    return (
      <FrameBox title="Update Password" isFull={false}>
        <div className="row form-group">
          <div className="col-md-4 form-label">New Password</div>
          <div className="col-md-8">
            <input type={'password'} className="form-input" value={password01} onChange={this.onChangePass01} />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4 form-label">Confirm New Password</div>
          <div className="col-md-8">
            <input type={'password'} className="form-input" value={password02} onChange={this.onChangePass02} />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4 form-label">2FA Code</div>
          <div className="col-md-8">
            <input type={'password'} className="form-input" value={tfaCode} onChange={this.onChangeTfaCode} />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-6 offset-md-4">
            <button type="submit" className="btn-submit" onClick={this.onUpdatePassword}>Update</button>
          </div>
        </div>
      </FrameBox>
    );
  }
}

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: { showSnackMsg, resetPassword },
     }) => ({
      showSnackMsg, resetPassword
    })
  )
)(PasswordReset);
