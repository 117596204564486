import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  
  > div {
    flex: 1;
  }
`;

export const List = styled.div`
  height: 100%;
  overflow: hidden;
  border: 1px solid rgba(101, 101, 101, 0.7);
  border-bottom: 0;
  ${props => !props.left && `
    margin-right: 20px;
  `}
`;

export const StyleWrapper = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  .scrollbar-container {
    height: min-content;
    max-height: 100%;
    overflow: hidden;

    &.d-flex {
      flex-wrap: wrap;
    }
  }

  .ps__rail-y {
    background-color: ${props => props.theme.palette.depositBackground} !important;
    border-left: 1px solid ${props => props.theme.palette.clrPurple};
    opacity: 1 !important;

    .ps__thumb-y {
      z-index: 9999;
      cursor: pointer;

      &:before {
        background-color: ${props => props.theme.palette.clrPurple};
      }
    }
  }

  .ReactVirtualized__Table__rowColumn {
    height: 100%;
    margin: 0;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:first-child) {
      border-left: 1px solid ${props => props.theme.palette.clrBorderLight};
    }
  }

  .ReactVirtualized__Table__row {
    padding-right: 15px !important;
    border-bottom: 1px solid ${props => props.theme.palette.clrBorderLight};

    &:hover {
      background-color: rgba(101, 101, 101, 0.5);
      color: ${props => props.theme.palette.clrHighContrast} !important;
    }
  }

  .ReactVirtualized__Table__Grid {
    * {
      user-select: none;
      outline: none;
  
      scrollbar-width: none;        /* hide scrollbar (firefox) */
      -ms-overflow-style: none;     /* hide scrollbar (ie, edge) */
    }
  
    /* hide scrollbar (chrome, safari) */
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .ReactVirtualized__Table__headerRow {
    display: flex;
    background-color: #fff;
    border-bottom: 1px solid rgba(101, 101, 101, 0.7);
    padding-right: 15px !important;

    ${props => props.myTradesHeaderAbove && 'bottom: 16px;'}
    // border-bottom-right-radius: 3px;
    // border-bottom-left-radius: 3px;

    .ReactVirtualized__Table__headerColumn {
      &:last-child {
        min-width: 12px;
      }
      &:not(:last-child) {
        border-right: 1px solid rgba(101, 101, 101, 0.7);
      }
    }
  }
  
  .ReactVirtualized__Table__row {
    display: flex;
    }
    
  .ReactVirtualized__Table__rowColumn {
    flex: 1;
  }
    
  .ReactVirtualized__Table__headerRow {
    display: flex;
  }
    
  .ReactVirtualized__Table__headerColumn {
    flex: 1;
  }
`;

export const HeaderWrapper = styled.div`
  border-collapse: collapse;
  box-sizing: border-box;
  color: #656565;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
  height: 28px;
  z-index: 3;
  -webkit-border-horizontal-spacing: 2px;
  -webkit-border-vertical-spacing: 2px;
  white-space: nowrap;
`;

export const Item = styled.div`
  color: #656565;
  font-size: 11px;
  overflow: hidden;
  display: block;

  .text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
`;
