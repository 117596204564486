import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: ${props => props.isFull ? '100%' : '66.66%'};
  box-sizing: border-box;
  color: #49545a;
  margin-bottom: 2rem;
  box-shadow: 0 2px 3px rgba(55,63,67,.16);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f6f8f9;
  background-clip: border-box;
  border: 1px solid #ddd;
  border-radius: .25rem;
  padding: 60px 32px 50px;
  
  > .small_title {
    margin-top: 10px;
    max-width: 348px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  > .head_title {
    margin-left: auto;
    margin-right: auto;
    max-width: 348px;
    width: 100%;
    text-align: left;
    
    span {
      font-weight: 700;
    }
  }
  
  .alert {
    text-align: left;
  }
`;

export const Input = styled.input`
  background-color:rgb(255, 255, 255);
  border-bottom-color:rgb(134, 134, 134);
  border-bottom-left-radius:4px;
  border-bottom-right-radius:4px;
  border-bottom-style:solid;
  border-bottom-width:1px;
  border-image-outset:0px;
  border-image-repeat:stretch;
  border-image-slice:100%;
  border-image-source:none;
  border-image-width:1;
  border-left-color:rgb(134, 134, 134);
  border-left-style:solid;
  border-left-width:1px;
  border-right-color:rgb(134, 134, 134);
  border-right-style:solid;
  border-right-width:1px;
  border-top-color:rgb(134, 134, 134);
  border-top-left-radius:4px;
  border-top-right-radius:4px;
  border-top-style:solid;
  border-top-width:1px;
  box-shadow:none;
  box-sizing:border-box;
  color:rgb(29, 28, 29);
  cursor:text;
  display:inline;
  font-family:Slack-Lato, appleLogo, sans-serif;
  font-size:20px;
  font-stretch:100%;
  font-style:normal;
  font-variant-caps:normal;
  font-variant-east-asian:normal;
  font-variant-ligatures:none;
  font-variant-numeric:normal;
  font-weight:400;
  height:50px;
  letter-spacing:normal;
  line-height:normal;
  margin-bottom:10px;
  margin-left:auto;
  margin-right:auto;
  margin-top: 0;
  max-width:100%;
  outline-color:rgb(29, 28, 29);
  outline-style:none;
  outline-width:0px;
  padding-bottom:12px;
  padding-left:12px;
  padding-right:12px;
  padding-top:12px;
  text-align:start;
  text-indent:0px;
  text-rendering:auto;
  text-shadow:none;
  text-transform:none;
  transition-delay:0s, 0s;
  transition-duration:0.07s, 0.07s;
  transition-property:box-shadow, border-color;
  transition-timing-function:ease-out, ease-out;
  vertical-align:baseline;
  width:347px;
  word-spacing:0px;
  writing-mode:horizontal-tb;
  -webkit-appearance:none;
  -webkit-font-smoothing:antialiased;
  -webkit-rtl-ordering:logical;
  -webkit-border-image:none;
`;

export const Button = styled.button`
  align-items:flex-start;
  background-attachment:scroll;
  background-clip:border-box;
  background-color: #3ebb9e;
  background-image:none;
  background-origin:padding-box;
  background-position-x:0%;
  background-position-y:0%;
  background-repeat-x:;
  background-repeat-y:;
  background-size:auto;
  border-bottom-color:rgb(255, 255, 255);
  border-bottom-left-radius:4px;
  border-bottom-right-radius:4px;
  border-bottom-style:none;
  border-bottom-width:0px;
  border-image-outset:0px;
  border-image-repeat:stretch;
  border-image-slice:100%;
  border-image-source:none;
  border-image-width:1;
  border-left-color:rgb(255, 255, 255);
  border-left-style:none;
  border-left-width:0px;
  border-right-color:rgb(255, 255, 255);
  border-right-style:none;
  border-right-width:0px;
  border-top-color:rgb(255, 255, 255);
  border-top-left-radius:4px;
  border-top-right-radius:4px;
  border-top-style:none;
  border-top-width:0px;
  box-shadow:none;
  box-sizing:border-box;
  color:rgb(255, 255, 255);
  cursor:pointer;
  display:inline-block;
  font-family:Slack-Lato, appleLogo, sans-serif;
  font-size:20px;
  font-stretch:100%;
  font-style:normal;
  font-variant-caps:normal;
  font-variant-east-asian:normal;
  font-variant-ligatures:normal;
  font-variant-numeric:normal;
  font-weight:900;
  height:49.8281px;
  letter-spacing:normal;
  line-height:16px;
  margin-bottom:0px;
  margin-left: auto;
  margin-right: auto;
  margin-top:8px;
  max-width:100%;
  padding-bottom:16px;
  padding-left:32px;
  padding-right:32px;
  padding-top:14px;
  position:relative;
  text-align:center;
  text-decoration-color:rgb(255, 255, 255);
  text-decoration-line:none;
  text-decoration-style:solid;
  text-indent:0px;
  text-rendering:auto;
  text-shadow:none;
  text-transform:none;
  transition-delay:0s;
  transition-duration:0.3s;
  transition-property:padding;
  transition-timing-function:cubic-bezier(0.175, 0.885, 0.32, 1.275);
  user-select:none;
  vertical-align:bottom;
  white-space:nowrap;
  width:348px;
  word-spacing:0px;
  writing-mode:horizontal-tb;
  -webkit-appearance:none;
  -webkit-font-smoothing:antialiased;
  -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
  -webkit-border-image:none;
`;

export const LinkTo = styled.a`
  color: #1264a3 !important;
`;
