import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "@/containers/Landing/Home";
import ProtectedRoute from "@/containers/Routes/ProtectedRoute";
import LoginEmail from "@/containers/Auth/LoginEmail";
import RegisterEmail from "@/containers/Auth/RegisterEmail";
import PasswordReset from "@/containers/Auth/PasswordReset";
import ConfirmEmail from "@/containers/Auth/ConfirmEmail";
import ResetPasswordRequest from "@/containers/Auth/ResetPasswordRequest";
import Terms from "@/containers/Terms";
import Privacy from "@/containers/Privacy";
import Main from "@/containers/Main";
import Layout from "@/containers/Layout";
import Error from "@/containers/Error";

export default () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={() => <Home />} />
        <Layout>
          <ProtectedRoute exact path="/home" component={() => <Main />} />
          <Route exact path="/login" component={() => <LoginEmail />} />
          <Route exact path="/register" component={() => <RegisterEmail />} />
          <Route exact path="/reset" component={() => <PasswordReset />} />
          <Route exact path="/confirm" component={() => <ConfirmEmail />} />
          <Route exact path="/terms" component={() => <Terms />} />
          <Route exact path="/gdpr-privacy-policy" component={() => <Privacy />} />
          <Route path={"/reset_password/:token"} component={(props) => <ResetPasswordRequest { ...props } />} />
          <Route exact path="/error" component={() => <Error />} />
        </Layout>
      </Switch>
    </Router>
  );
};
