import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { createMuiTheme } from '@material-ui/core/styles';
import { MuiThemeProvider } from "@material-ui/core";

import tableIcons from './icons';

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.theme = createMuiTheme({
      palette: {
        primary: {
          main: '#4caf50',
        },
        secondary: {
          main: '#ff9100',
        },
      },

    });
  }

  render() {
    const { title, columns, data } = this.props;

    return (
      <MuiThemeProvider theme={this.theme}>
        <MaterialTable
          title={title}
          icons={tableIcons}
          options={{
            exportAllData: true,
            exportButton: true,
            pageSize: 10,
            // paginationType: 'stepped',
          }}
          columns={columns}
          data={data}
        />
      </MuiThemeProvider>
    );
  }
}

Table.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default Table;
