import React, { Component, createRef } from 'react';
import { Wrapper } from './styles';

// A functional component to keep it simple
class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };
  }

  handleKeyDown = (event) => {
    const { wrapperRefs, index } = this.props;

    // Handle the delete/backspace key
    if (event.keyCode === 8 || event.keyCode === 46) {
      this.setState({
        value: ''
      });

      return;
    }

    // Handle the tab key
    if (event.keyCode === 9) {
      return;
    }

    // Handle numbers and characters
    const key = String.fromCharCode(event.which);
    if (Number.isInteger(Number(key))) {
      this.setState({
        value: key
      }, () => {
        // Move focus to next input
        wrapperRefs[(index + 1) % 6].current.focus()
      });
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const { value } = this.state;
    if (nextProps.value !== value) {
      this.setState({
        value: nextProps.value
      })
    }
  }

  render() {
    const { wrapperRefs, index } = this.props;
    const { value } = this.state;

    return (
      <div className="inputContainer">
        <input
          className="input"
          value={value}
          onKeyDown={this.handleKeyDown}
          ref={wrapperRefs[index]}
          maxLength="1"
        />
      </div>
    )
  }
}

class InputConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null
    };
  }

  handlePaste = (event) => {
    event.clipboardData.items[0].getAsString(text => {
      const myText = (text || '').replace('-', '');
      const code = myText.split("").map((char, index) => {
        if (Number.isInteger(Number(char))) {
          return Number(char);
        }

        return "";
      });

      this.setState({
        code
      });
    })
  }

  render() {
    const { code } = this.state;
    const { wrapperRefs } = this.props;

    return (
      <Wrapper>
        <div className="container" onPaste={this.handlePaste}>
          <Input value={code && code[0]} index={0} wrapperRefs={wrapperRefs} />
          <Input value={code && code[1]} index={1} wrapperRefs={wrapperRefs} />
          <Input value={code && code[2]} index={2} wrapperRefs={wrapperRefs} />
          <div className="spacer">-</div>
          <Input value={code && code[3]} index={3} wrapperRefs={wrapperRefs} />
          <Input value={code && code[4]} index={4} wrapperRefs={wrapperRefs} />
          <Input value={code && code[5]} index={5} wrapperRefs={wrapperRefs} />
        </div>
      </Wrapper>
    )
  }
}

export default InputConfirm;
