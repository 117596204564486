import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import FrameBox from '@/components/FrameBox';
import CoinSelector from "./CoinSelector";
import CoinItem from "./CoinItem";
import Onboard from "./Onboard";
import { pAssetCoins } from "@/stores/CoinsHandleStore";
import { STORE_KEYS } from '@/stores';

class Balance extends Component {
  render() {
    const { activeCoins, addCoin, openOnboard, setOpenOnboard } = this.props;
    return (
      <>
        <FrameBox title="My Wallets" isFull={true} nopadding={true}>
          {
            (Object.values(activeCoins) || []).map((item, index) => (
              <CoinItem name={item.name} icon={item.icon} symbol={item.symbol} key={index}/>
            ))
          }
          <CoinSelector
            data={pAssetCoins}
            addCoin={addCoin}
          />
          <Onboard open={openOnboard} setOpen={setOpenOnboard}/>
        </FrameBox>
      </>
    );
  }
}

export default compose(
  inject(STORE_KEYS.COINSHANDLESTORE, STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.COINSHANDLESTORE]: {
         activeCoins,
         addCoin,
         selectCoin,
       },
       [STORE_KEYS.AUTHSTORE]: {
         openOnboard,
         setOpenOnboard,
       },
     }) => ({
      activeCoins,
      addCoin,
      selectCoin,
      openOnboard,
      setOpenOnboard,
    })
  )
)(Balance);
