import styled from 'styled-components';

export const Wrapper = styled.div`
  
`;

export const ASide = styled.div`
  width: 100%;
`;

export const NavHeading = styled.div`
  background-color:rgba(0, 0, 0, 0);
  box-sizing:border-box;
  color:rgb(154, 165, 172);
  direction:ltr;
  display:block;
  font-size:14px;
  font-weight:500;
  height:16px;
  letter-spacing:1px;
  line-height:16.8px;
  margin-block-end:10px;
  margin-block-start:0px;
  margin-bottom:10px;
  margin-inline-end:0px;
  margin-inline-start:0px;
  margin-top:0px;
  padding-bottom:0px;
  padding-left:0px;
  padding-right:0px;
  padding-top:0px;
  text-align:left;
  text-size-adjust:100%;
  text-transform:uppercase;
  -webkit-tap-highlight-color:rgba(55, 63, 67, 0);
`;

export const UL = styled.ul`
  box-sizing:border-box;
  color:rgb(73, 84, 90);
  direction:ltr;
  display:flex;
  flex-direction:column;
  flex-wrap:wrap;
  font-size:16px;
  font-weight:400;
  line-height:24px;
  list-style-image:none;
  list-style-position:outside;
  list-style-type:none;
  margin-block-end:24px;
  margin-block-start:0px;
  margin-bottom:24px;
  margin-inline-end:0px;
  margin-inline-start:0px;
  margin-top:0px;
  padding-inline-start:0px;
  padding-left:0px;
  text-align:left;
  text-size-adjust:100%;
  width:100%;
`;

export const LI = styled.li`
  box-sizing:border-box;
  color:rgb(73, 84, 90);
  direction:ltr;
  display:list-item;
  font-size:16px;
  font-weight:400;
  height:39px;
  line-height:24px;
  list-style-image:none;
  list-style-position:outside;
  list-style-type:none;
  text-align:left;
  text-size-adjust:100%;
  width:100%;
  
  > a {
    align-items:center;
    background-color:rgba(0, 0, 0, 0);
    box-sizing:border-box;
    color: rgb(73, 84, 90);
    cursor:pointer;
    direction:ltr;
    display:flex;
    font-size:16px;
    font-weight:600;
    height:39px;
    line-height:24px;
    list-style-image:none;
    list-style-position:outside;
    list-style-type:none;
    padding-bottom:10px;
    padding-left:0px;
    padding-right:0px;
    padding-top:5px;
    text-align:left;
    text-decoration-color:rgb(73, 84, 90);
    text-decoration-line:none;
    text-decoration-style:solid;
    text-size-adjust:100%;
    width:255px;
    
    > span {
      padding-top: 2px;
    }
    
    &:hover {
      font-weight: 600;
      color: #3ebb9e;
      
      > svg {
        fill: #3ebb9e;
      }
    }
    
    ${props => props.active && `
      font-weight: 600;
      color: #3ebb9e;
      background: #fff;
      border-left: 5px solid #3ebb9e;
      margin-left: -13px;
      padding-left: 8px;
      
      > svg {
        fill: #3ebb9e;
      }
    `}
  }
`;
