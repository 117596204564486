import React, { Component } from 'react';
import FrameBox from '@/components/FrameBox';

class API extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: '',
      secret: '',
      delegator: '',
    }
  }

  render() {
    const {
      key, secret, delegator,
    } = this.state;

    return (
      <>
        <FrameBox title="API Keys" isFull={true}>
          <div className="row form-group">
            <div className="col-md-4 form-label">API Key</div>
            <div className="col-md-8">
              <input type={'text'} className="form-input" value={key}/>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-4 form-label">API Secret</div>
            <div className="col-md-8">
              <input type={'text'} className="form-input" value={secret}/>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-4 form-label">Delegator Address</div>
            <div className="col-md-8">
              <input type={'text'} className="form-input" value={delegator}/>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-6 offset-md-4">
              <button type="submit" className="btn-submit">Add</button>
            </div>
          </div>
        </FrameBox>
      </>
    );
  }
}

export default API;
