import styled from 'styled-components';

export const Wrapper = styled.div`
  .spark-uploader-control {
    align-items:baseline;
    background-color:rgba(0, 0, 0, 0);
    border-bottom-color:rgb(73, 84, 90);
    border-bottom-style:none;
    border-bottom-width:0px;
    border-image-outset:0px;
    border-image-repeat:stretch;
    border-image-slice:100%;
    border-image-source:none;
    border-image-width:1;
    border-left-color:rgb(73, 84, 90);
    border-left-style:none;
    border-left-width:0px;
    border-right-color:rgb(73, 84, 90);
    border-right-style:none;
    border-right-width:0px;
    border-top-color:rgb(73, 84, 90);
    border-top-style:none;
    border-top-width:0px;
    bottom:0px;
    box-sizing:border-box;
    color:rgb(73, 84, 90);
    cursor:default;
    direction:ltr;
    display:block;
    font-size:16px;
    font-stretch:100%;
    font-style:normal;
    font-variant-caps:normal;
    font-variant-east-asian:normal;
    font-variant-ligatures:normal;
    font-variant-numeric:normal;
    font-weight:400;
    height:36px;
    letter-spacing:normal;
    line-height:24px;
    margin-bottom:0px;
    margin-left:0px;
    margin-right:0px;
    margin-top:0px;
    max-width:100%;
    opacity:0;
    overflow-wrap:break-word;
    overflow-x:visible;
    overflow-y:visible;
    padding-bottom:0px;
    padding-left:0px;
    padding-right:0px;
    padding-top:0px;
    position:absolute;
    text-align:start;
    text-indent:0px;
    text-rendering:auto;
    text-shadow:none;
    text-size-adjust:100%;
    text-transform:none;
    top:0px;
    width:119.594px;
    word-spacing:0px;
    writing-mode:horizontal-tb;
    z-index:99;
    -webkit-appearance:none;
    -webkit-box-direction:normal;
    -webkit-rtl-ordering:logical;
    -webkit-tap-highlight-color:rgba(55, 63, 67, 0);
    -webkit-border-image:none;
  }
  
  .btn-outline-dark {
    border-color: #c7cdd1;
    color: #6e7d87;
    font-weight: 300;
    box-shadow: none;
  }
`;

export const ProfilePhotoPreview = styled.span`
  display: inline-block;
  background-image: url(https://www.gravatar.com/avatar/b3e51c7….jpg?s=200&d=mm);
  background-position: 50%;
  background-size: cover;
  vertical-align: middle;
  height: 60px;
  width: 60px;
  border-radius: 50%;
`;
