import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import FrameBox from '@/components/FrameBox';
import { Wrapper, Label } from './styles';
import { STORE_KEYS } from '@/stores';

class Export extends Component {
  constructor(props) {
    super(props);

    this.state = {
      twofactcode: '',
    };
  }

  componentDidMount() {
    const { resetKeys } = this.props;
    resetKeys();
  }

  onChangeTwofactcode = (e) => {
    this.setState({
      twofactcode: e.target.value || '',
    });
  };

  exportAccount = () => {
    const { twofactcode } = this.state;
    const { exportAccount } = this.props;
    exportAccount(twofactcode);
  };

  onChangePass = (e) => {
    this.setState({
      pass: e.target.value || '',
    });
  };

  verifyPassword = () => {
    const { pass } = this.state;
    const { verifyPass } = this.props;
    verifyPass(pass);
  };

  render() {
    const { pass, twofactcode } = this.state;
    const {
      publicAddr, secret, exportStep,
    } = this.props;

    return (
      <Wrapper>
        <FrameBox title="Export Account" isFull={true}>
          {exportStep === 'INIT' ? (
            <>
              <div className="row form-group">
                <div className="col-md-4 form-label">Enter your password</div>
                <div className="col-md-8">
                  <input type={'password'} className="form-input" value={pass} onChange={this.onChangePass}/>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-4 form-label">
                </div>
                <div className="col-md-8">
                  <Label>
                    Never share your secret key / private key with anyone. Write down your secret key OFFLINE, keep multiple copies, it contains all your money in the account.
                  </Label>
                  <button type="submit" className="btn-submit" onClick={this.verifyPassword}>Export</button>
                </div>
              </div>
            </>
          ) : (exportStep === 'CONFIRM' ? (
            <>
              <div className="row form-group">
                <div className="col-md-4 form-label">2FA code</div>
                <div className="col-md-8">
                  <input type={'text'} className="form-input" value={twofactcode} onChange={this.onChangeTwofactcode}/>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-4 form-label">
                </div>
                <div className="col-md-8">
                  <Label>
                    Enter Google Authenticator Code Here. <br/>You should enable two step verification before you can export your account.
                  </Label>
                  <button type="submit" className="btn-submit" onClick={this.exportAccount}>Export</button>
                </div>
              </div>
            </>
          ) : exportStep === 'COMPLETED' && (
            <>
              <div className="row form-group">
                <div className="col-md-4 form-label">
                  Public Address:
                </div>
                <div className="col-md-8">
                  {publicAddr}
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-4 form-label">
                  Secret Key:
                </div>
                <div className="col-md-8">
                  {secret}
                </div>
              </div>
            </>
          ))}
        </FrameBox>
      </Wrapper>
    );
  }
}

export default compose(
  inject(STORE_KEYS.SECURITYSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.SECURITYSTORE]: {
         exportAccount,
         publicAddr,
         secret,
         exportStep,
         resetKeys,
         verifyPass,
       },
     }) => ({
      exportAccount,
      publicAddr,
      secret,
      exportStep,
      resetKeys,
      verifyPass,
    })
  )
)(Export);
