import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';

import { STORE_KEYS } from '@/stores';
import { Wrapper, Input, Button, LinkTo } from './styles';
import { Redirect } from "react-router-dom";

class RegisterEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      repassword: '',
      checked: false,
    };
  }

  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value
    });
  };

  onChangePassword = (e) => {
    this.setState({
      password: e.target.value
    });
  };

  onChangeRePassword = (e) => {
    this.setState({
      repassword: e.target.value
    });
  };

  goSignUp = () => {
    // validation
    let { email, password, repassword, checked } = this.state;
    const { showSnackMsg } = this.props;
    email = (email || '').trim();
    password = (password || '').trim();
    repassword = (repassword || '').trim();

    if (email.length === 0 || password.length === 0) {
      showSnackMsg('Email or Password is not valid.');
      return;
    }

    if (password !== repassword) {
      showSnackMsg('Password does not match.');
      return;
    }

    if (!checked) {
      showSnackMsg('You should agree to terms and privacy');
      return;
    }

    // api call
    this.props.registerWithMail(email, password)
      .then(() => {
        this.props.setPendingEmail(email);
        this.setRedirect();
      })
      .catch(err => {
        showSnackMsg('Sorry, your registration is failed.');
      });
  };

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/confirm' />
    }
  };

  checkAgreement = () => {
    const { checked } = this.state;
    this.setState({
      checked: !checked,
    });
  };

  render() {
    const { email, password, repassword, checked } = this.state;

    return (
      <Wrapper>
        {this.renderRedirect()}
        <h3>Get Your Free Account</h3><br/>
        <p className="head_title">Enter your <span>email address</span> and <span>password</span>.</p>
        <Input type="text" placeholder="you@example.com" value={email} onChange={this.onChangeEmail}/>
        <Input type="password" placeholder="password" value={password} onChange={this.onChangePassword}/>
        <Input type="password" placeholder="retype password" value={repassword} onChange={this.onChangeRePassword}/>

        <Button onClick={this.goSignUp}>Sign Up with Email</Button>
        <label className="agreement">
          <input type="checkbox" className="checkbox form-control" checked={checked} onClick={this.checkAgreement} />
          <p className="small_title">
            By clicking accept, you're agreeing to our <a href={"/terms"} target={"_blank"}>Customer Terms of Service</a>, <a href={"/gdpr-privacy-policy"} target={"_blank"}>Privacy Policy</a>, and <a href={"/gdpr-privacy-policy"} target={"_blank"}>Cookie Policy</a>.
          </p>
        </label>
      </Wrapper>
    );
  }
}

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: { registerWithMail, setPendingEmail, showSnackMsg },
     }) => ({
      registerWithMail,
      setPendingEmail,
      showSnackMsg,
    })
  )
)(RegisterEmail);
