import React, { Component } from "react";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import AsksTable from "./Asks";
import BidsTable from "./Bids";
import { Wrapper } from './styles';
import { STORE_KEYS } from '../../../../stores';

class OrderBook extends Component {
  render() {
    return (
      <Wrapper>
        <BidsTable/>
        <AsksTable/>
      </Wrapper>
    );
  }
}

export default compose(
  inject(STORE_KEYS.ORDERBOOKSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.ORDERBOOKSTORE]: { },
     }) => ({ })
  )
)(OrderBook);
