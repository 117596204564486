import React from "react";

import Header from '@/components/DashHeader';
import { Wrapper, Content } from "./styles.js";

export default (props) => {
  return (
    <Wrapper>
      <Header/>
      <Content>
        <div>
          {props.children}
        </div>
      </Content>
    </Wrapper>
  );
};
