import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { Bal, Icon, Item, Label } from "../styles";
import { STORE_KEYS } from '@/stores';

function ExchangeItem(props) {
  const {
    name, symbol, icon, url,
  } = props;

  return (
    <div>
      <Item>
        <Icon name={icon} href={url} target={"_blank"}/>
        <Label href={url} target={"_blank"}>
          <div>{name}</div>
          <span>{symbol}</span>
        </Label>
        <Bal enabled={true}>
          <div className="usd_value">Connect</div>
        </Bal>
      </Item>
    </div>
  );
}

export default compose(
  inject(
    STORE_KEYS.REALDEPOSITSTORE,
    STORE_KEYS.COINSHANDLESTORE,
    STORE_KEYS.COINPRICESTORE,
  ),
  observer,
  withProps(
    ({
       [STORE_KEYS.REALDEPOSITSTORE]: { fctAddr, myBalances, priceUpdatedAt },
       [STORE_KEYS.COINSHANDLESTORE]: { removeCoin },
       [STORE_KEYS.COINPRICESTORE]: { getCoinPrice },
     }) => ({
      fctAddr,
      myBalances,
      priceUpdatedAt,
      removeCoin,
      getCoinPrice,
    })
  )
)(ExchangeItem);
