import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import FrameBox from '@/components/FrameBox';
import { STORE_KEYS } from '@/stores';
import { ProfilePhotoPreview } from '../../styles';

class Profile extends Component {
  render() {
    const { email } = this.props;
    return (
      <>
        <FrameBox title="Profile Photo" isFull={true}>
          <div className="row form-group">
            <div className="col-md-4 form-label">
              <ProfilePhotoPreview/>
            </div>
            <div className="col-md-8">
              <div className="spark-uploader mr-4">
                <input type="file" name="photo" className="spark-uploader-control"/>
                <div className="btn btn-outline-dark">Update Photo</div>
              </div>
            </div>
          </div>
        </FrameBox>
        <FrameBox title="Contact Information" isFull={true}>
          <div className="row form-group">
            <div className="col-md-4 form-label">E-Mail Address</div>
            <div className="col-md-8">
              <input type={'text'} className="form-input" value={email}/>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-6 offset-md-4">
              <button type="submit" className="btn-submit">Update</button>
            </div>
          </div>
        </FrameBox>
      </>
    );
  }
}

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: { email },
     }) => ({
      email,
    })
  )
)(Profile);
