import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import { NotifyPill } from "@/components/DashHeader/styles";
import { SvgBell2Icon } from "@/components/SvgIcon";

const useStyles = makeStyles({
  list: {
    width: 335,
    backgroundColor: '#f4f5f6',
    height: '100%'
  },
  fullList: {
    width: 'auto',
  },
  btnGroup: {
    width: '100%'
  },
  btnLight: {
    color: '#49545a',
    backgroundColor: '#fff',
    borderColor: '#d1d6d9',
    fontSize: '15px',
    width: '50%',
  },
  btn01: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  btn02: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});

export default function Notification() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
    viewMode: true, // true: notification, false: announcement
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const toggleViewMode = (mode) => event => {
    setState({ ...state, viewMode: mode });
  };

  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
    >
      <div className="modal-header text-center">
        <div className={classes.btnGroup}>
          <button
            className={`btn btn-light ${classes.btnLight} ${classes.btn01} ${!state.viewMode && 'active'}`}
            onClick={toggleViewMode(true)}
          >
            Notifications
          </button>
          <button
            className={`btn btn-light ${classes.btnLight} ${classes.btn02} ${state.viewMode && 'active'}`}
            onClick={toggleViewMode(false)}
          >
            Announcements
          </button>
        </div>
      </div>
      <p className="alert alert-warning">
        We don't have anything to show you right now! But when we do, we'll be sure to let you know. Talk to you soon!
      </p>
    </div>
  );

  return (
    <>
      <NotifyPill onClick={toggleDrawer('right', true)}>
        <SvgBell2Icon/>
        <span>0</span>
      </NotifyPill>
      <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
        {sideList('right')}
      </Drawer>
    </>
  );
}
