import React from 'react';
import FrameBox from '@/components/FrameBox';

export default () => {
  return (
      <FrameBox title={'pTrader.co | End-User License Agreement (EULA)'} isFull>
        <p>This End-User License Agreement ("EULA") is a legal agreement between you and pTrader</p>
        <p>This EULA agreement governs your acquisition and use of our pTrader.co software ("Software") directly from pTrader</p>
        <p>Please read this EULA agreement carefully before completing the sign-up process and using the pTrader.co software. It provides a license to use the pTrader.co software and contains warranty information and liability disclaimers.</p>
        <p>By clicking "accept" or installing and/or using the pTrader.co software, you are confirming your acceptance of the Software and agreeing to become bound by the terms of this EULA agreement.</p>
        <p>If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not use the Software, and you must not accept this EULA agreement.</p>
        <p>This EULA agreement shall apply only to the owner of the account which is signing up for its use supplied by pTrader herewith regardless of whether other software is referred to or described herein. The terms also apply to any pTrader updates, supplements, Internet-based services, and support services for the Software, unless other terms accompany those items on delivery. If so, those terms apply.</p>
        <h5>License Grant</h5>
        <p>pTrader hereby grants you a personal, non-transferable, non-exclusive licence to use the pTrader.co software on your devices in accordance with the terms of this EULA agreement.</p>
        <p>You are permitted to access the pTrader.co software (for example a PC, laptop, mobile or tablet) under your control. You are responsible for ensuring your device meets the minimum requirements of the pTrader.co software.</p>
        <p>You are not permitted to:</p>
        <ul>
          <li>Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit the whole or any part of the Software to be combined with or become incorporated in any other software, nor decompile, disassemble or reverse engineer the Software or attempt to do any such things</li>
          <li>Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose</li>
          <li>Allow any third party to use the Software on behalf of or for the benefit of any third party</li>
          <li>Use the Software in any way which breaches any applicable local, national or international law</li>
          <li>Use the Software for any purpose that pTrader considers is a breach of this EULA agreement</li>
        </ul>
        <h5>Warranties and liabilities and terms of support</h5>
        <p>The pTrader.co software is a free non-custodial service. By clicking “accept” to this agreement you waive liability from pTrader.co, its developers, or affiliates. The user agrees to taking sole responsibility for generating, keeping, and maintaining all Passwords, Private Keys, and Google Authenticator Backups. pTrader.co does not keep copies of this information and is not responsible for their recovery.</p>
        <p>By agreeing to this and using the pTrader.co software, the user understands that they are responsible for their own funds and the safe interaction with the software.</p>
        <p>pTrader is not responsible for any "forked assets" that are sent to the user's address. The user has access to their private key / secret key in the pTrader account and can choose to export their funds and import them to another wallet at any time.</p>
        <p>In the case of the pTrader service being deprecated for any reason, users will be required to withdraw their funds from the service. Any user failing to withdraw their funds 3 years after the deprecation of the pTrader service, will have their private key / secret key emailed to them in a file that can be unlocked with their pTrader account password.</p>
        <h5>Information privacy</h5>
        <p>The pTrader website collects the user’s name, email, and cookies as part of its operation. The user agrees before creating an account to the collection and storage of this information.</p>
        <h5>Intellectual Property and Ownership</h5>
        <p>pTrader shall at all times retain ownership of the Software as originally accessed by you and all subsequent logins of the Software by you. The Software (and the copyright, and other intellectual property rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the property of pTrader.</p>
        <p>pTrader reserves the right to grant licences to use the Software to third parties.</p>
        <h5>Termination</h5>
        <p>This EULA agreement is effective from the date you first use the Software and shall continue until terminated. You may terminate it at any time upon written notice to pTrader.</p>
        <p>It will also terminate immediately if you fail to comply with any term of this EULA agreement. Upon such termination, the licenses granted by this EULA agreement will immediately terminate and you agree to stop all access and use of the Software. The provisions that by their nature continue and survive will survive any termination of this EULA agreement.</p>
        <h5>Governing Law</h5>
        <p>This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be governed by and construed in accordance with the laws of Switzerland.</p>
      </FrameBox>
  );
};
