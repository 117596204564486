import React from 'react';
import {
  createMuiTheme, makeStyles, ThemeProvider
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Contents from "./Contents";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  addButton: {
    position: 'absolute',
    top: '7px',
    left: '120px',
    color: 'rgb(73,84,90)',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function MaxWidthDialog({ open, setOpen }) {
  const classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = event => {
    setFullWidth(event.target.checked);
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        <h4 className="text-center">Welcome aboard!</h4>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Contents handleClose={handleClose}/>
      </DialogContent>
    </Dialog>
  );
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(73,84,90)',
    },
    secondary: {
      main: '#ff9100',
    },
  },
});

export default function CustomStyles({ open, setOpen }) {
  return (
    <ThemeProvider theme={theme}>
      <MaxWidthDialog open={open} setOpen={setOpen} />
    </ThemeProvider>
  );
}
