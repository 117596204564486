import React from 'react';
import PropTypes from 'prop-types';
import {
  createMuiTheme, makeStyles, ThemeProvider
} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import AddCoinIcon from '@material-ui/icons/AddToQueue';
import { Icon } from "../styles";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  addIcon: {
    color: 'rgb(73,84,90)',
  },
  dlgTitle: {
    color: 'rgb(73,84,90)',
  },
  dialog: {
    maxHeight: '400px',
  },
  addButton: {
    position: 'absolute',
    top: '5px',
    right: '0',
    marginRight: '10px',
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, passets, addCoin } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    if (value !== 'addAccount') {
      addCoin(value);
    }
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        <span className={classes.dlgTitle}>Select Assets</span>
      </DialogTitle>
      <List
        className={classes.dialog}
      >
        {passets.map(item => {
          const name = `${item.symbol} - ${item.name}`;
          const icon = item.icon;
          return (
            <ListItem button onClick={() => handleListItemClick(name)} key={name}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <Icon name={icon}/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={name} />
            </ListItem>
          )
        })}

        <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add a new asset" />
        </ListItem>
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

function CoinSelector(props) {
  const {
    data: passets,
    addCoin,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(`${passets[1].symbol} - ${passets[1].name}`);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <IconButton aria-label="delete" className={classes.addButton} onClick={handleClickOpen}>
        <AddCoinIcon className={classes.addIcon}/>
      </IconButton>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        passets={passets}
        addCoin={addCoin}
      />
    </div>
  );
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(73,84,90)',
    },
    secondary: {
      main: '#ff9100',
    },
  },
});

export default function CustomStyles(props) {
  const { data, addCoin } = props;
  return (
    <ThemeProvider theme={theme}>
      <CoinSelector
        data={data}
        addCoin={addCoin}
      />
    </ThemeProvider>
  );
}
