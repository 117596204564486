import React from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import { Wrapper, Code, Icon } from './styles';
import { commafyDigitFormat } from "@/utils";

class Deposit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dismiss: false,
    }
  }

  onEnableMode = () => {
    const { setViewMode } = this.props;
    setViewMode('security');
  };

  onDismissMode = () => {
    this.setState({
      dismiss: true,
    });
  };

  render() {
    const {
      name, symbol, icon, fctAddr, myBalances, is_tfa,
    } = this.props;
    const { dismiss } = this.state;

    const balance = (myBalances[symbol] || 0) / 100000000;

    let address = '';
    if (symbol === 'BTC') {
      address = '1DMDABnWUCHt71gTqmUzmZjFP7gqtieiU7';
    } else if (symbol === 'ETH') {
      address = '0x13dF1d8F51324a237552E87cebC3f501baE2e972';
    } else {
      address = fctAddr;
    }

    return (
      <Wrapper>
        {(!is_tfa && !dismiss) ? (
          <div className="recommend">
            <div>Please enable Two-step verification from security page to prevent unauthorized access to your wallet.</div><br/>
            <div>
              <button className="btn_enable" onClick={this.onEnableMode}>Enable</button>
              <button className="btn_dismiss" onClick={this.onDismissMode}>Cancel</button>
            </div>
          </div>
        ) : (
          <>
            <Code value={address} />
            <p>
              Your Receiving <span className="symbol">{symbol}&nbsp;<Icon name={icon}/></span> Wallet Address
            </p>
            <h6>
              {address}
            </h6>
            <span className="warning">balance: {commafyDigitFormat(balance, 8)} {symbol}</span>
          </>
        )}
      </Wrapper>
    );
  }
}

export default compose(
  inject(
    STORE_KEYS.REALDEPOSITSTORE, STORE_KEYS.SECURITYSTORE, STORE_KEYS.VIEWMODESTORE
  ),
  observer,
  withProps(
    ({
       [STORE_KEYS.REALDEPOSITSTORE]: { fctAddr, myBalances },
       [STORE_KEYS.SECURITYSTORE]: { is_tfa },
       [STORE_KEYS.VIEWMODESTORE]: { setViewMode },
     }) => ({
      fctAddr,
      myBalances,
      is_tfa,
      setViewMode,
    })
  )
)(Deposit);
