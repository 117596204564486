import socketCluster from 'socketcluster-client';
import { Observable } from 'rxjs';
import { filter, map, publishReplay, refCount, retry, repeat } from 'rxjs/operators';

export const destroySocket = socket => {
  if (socket) {
    socket.off();
    socket.destroy();
  }
};

export const createSCConnection = options => {
  return Observable.create(async subscriber => {
    const socketOptions = { ...options };
    const token = localStorage.getItem('authToken');

    if (token) {
      socketOptions.query = {
        token
      };
    }
    const socket = socketCluster.create(socketOptions);

    socket.on('connect', () => {
      subscriber.next({ socket, connected: true });
    });

    socket.on('error', error => {
      console.log('socket error', error);
      subscriber.next({ socket, connected: false });

      if (error.code >= 4500) {
        destroySocket(socket);
        subscriber.complete();
      }
      if (error && error.code === 4008) {
        // TokenExpiredError: jwt expired (Server rejected handshake from client)
        localStorage.clear();
        window.location.reload();
      }
    });

    return () => {
      destroySocket(socket);
    };
  }).pipe(
    retry(),
    repeat(),
    publishReplay(1),
    refCount()
  );
};

export const getOpenConnection = obs => {
  return obs.pipe(
    filter(({ connected }) => connected),
    map(({ socket }) => socket),
    publishReplay(1),
    refCount()
  );
};
