import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import Table from '@/components/Table';
import { STORE_KEYS } from '@/stores';
import { preventOverflow } from '@/utils/constants';
import { commafyDigitFormat } from "@/utils";

class History extends Component {
  componentDidMount() {
    this.props.getTxHistory();
  }

  render() {
    const { txHistoryData: data, getCoinPrice } = this.props;
    const columns = [
      {
        title: 'Date',
        field: 'timestamp',
        headerStyle: {
          textAlign: 'center',
          whiteSpace: 'nowrap',
        },
        cellStyle: {
          textAlign: 'center',
          whiteSpace: 'nowrap',
        },
        render: (rowData) => {
          const d = new Date(rowData.timestamp);
          return `${d.getFullYear()}-${`0${d.getMonth() + 1}`.substr(-2, 2)}-${`0${d.getDate()}`.substr(-2, 2)} ${`0${d.getHours()}`.substr(-2, 2)}:${`0${d.getMinutes()}`.substr(-2, 2)}`;
        },
      },
      {
        title: 'TRANSACTION ID',
        field: 'txid',
        headerStyle: {
          maxWidth: 250,
          ...preventOverflow,
          textAlign: 'center',
        },
        cellStyle: {
          maxWidth: 250,
          ...preventOverflow,
          textAlign: 'center',
        },
        render: (rowData) => (rowData.txid !== 'N/A' ? (
          <a href={`https://explorer.pegnetmarketcap.com/transactions/${rowData.txid}`} target="_blank" rel="noopener noreferrer">
            {rowData.txid}
          </a>
        ) : rowData.txid),
      },
      {
        title: 'AMOUNT',
        field: 'fromamount',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          ...preventOverflow,
          textAlign: 'center',
        },
        render: (rowData) => (
          <>
            {rowData.fromamount / 10e7} {rowData.fromasset}
            &nbsp;( ${commafyDigitFormat(rowData.fromamount / 10e7 * getCoinPrice(rowData.fromasset), 3)} )
          </>
        ),
      },
      {
        title: 'TYPE',
        field: 'currency',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
          ...preventOverflow,
        },
        render: (rowData) => (
          <>
            {rowData.txaction === 1 ? 'Transfer' : rowData.txaction === 2 ? 'Conversion' : ''}
          </>
        )
      },
    ];

    return (
      <>
        <Table
          title="Transaction History"
          columns={columns}
          data={data}
        />
      </>
    );
  }
}

export default compose(
  inject(
    STORE_KEYS.HISTORYSTORE,
    STORE_KEYS.COINPRICESTORE,
  ),
  observer,
  withProps(
    ({
       [STORE_KEYS.HISTORYSTORE]: {
         txHistoryData,
         getTxHistory,
       },
       [STORE_KEYS.COINPRICESTORE]: {
         getCoinPrice,
       },
     }) => ({
      txHistoryData,
      getTxHistory,
      getCoinPrice,
    })
  )
)(History);
