import styled from 'styled-components';
import QRCode from "qrcode.react";

export const Label = styled.p`
  // font-style: italic;
`;

export const Wrapper = styled.div`
  .MuiSwitch-track {
    background-color: #000;
  }
`;
