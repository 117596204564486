import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  width: calc(100% - 30px);
  border-bottom: 1px solid rgb(216, 218, 220);
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
`;

export const Icon = styled.a`
  width: 40px;
  height: 40px;
  background: ${props => `url('/assets/img/exchanges/${props.name}')`} no-repeat;
  background-size: 100% 100%;
  border-radius: 50% 50%;
`;

export const Bal = styled.div`
  font-weight: 600;
  padding-left: 15px;
  font-size: 20px;  
  margin-left: auto;
  text-align: right;
  ${props => !props.enabled && `
    color: grey;
  `}
  
  > span {
    display: block;
  }
  
  .usd_value {
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
  }
`;

export const Price = styled.div`
  font-weight: 400;
  padding-left: 15px;
  font-size: 14px; 
`;

export const Label = styled.a`
  font-size: 14px;
  font-weight: 600;
  padding-left: 15px;
  min-width: 150px;
  color: unset;
  text-decoration: unset !important;
  
  > span {
    color: rgb(154,165,172);
    font-weight: 400;
  }
`;

export const ActionWrapper = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  outline: none;
  
  .btn_action {
    width: calc(30% - 10px);
    height: 50px;
  }
`;
