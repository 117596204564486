import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: .25rem;
  border: 1px solid #ddd;
  padding: 60px;
  position: relative;
  box-shadow: 0 10px 25px #ddd;
  max-width: 528px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  
  .very_small_bottom_margin {
    color: #1d1c1d;
    font-weight: 800;
    font-size: 28px;
    line-height: 1.2143;
    letter-spacing: 0;
  }
  
  .desc {
    color: #616061;
    margin-bottom: 0;
  }
  
  .desc2 {
    color: #616061;
    margin-bottom: 32px;
  }
  
  .notify_text {
    color: #616061;
    margin-top: 20px;
  }
  
  .confirmation_code {
    align-items:center;
    box-sizing:border-box;
    color:rgb(29, 28, 29);
    display:flex;
    font-size:18px;
    font-variant-ligatures:common-ligatures;
    height:74px;
    line-height:24px;
    margin-bottom:8px;
    text-size-adjust:100%;
    width:406px;
    -webkit-font-smoothing:antialiased;
  }
  
  .confirmation_code_group {
    box-sizing:border-box;
    color:rgb(29, 28, 29);
    display:flex;
    font-size:18px;
    font-variant-ligatures:common-ligatures;
    height:74px;
    line-height:24px;
    text-size-adjust:100%;
    width:189.047px;
    -webkit-font-smoothing:antialiased;
  }
  
  .confirmation_code_span_cell {
    box-sizing:border-box;
    color:rgb(29, 28, 29);
    display:block;
    font-size:15px;
    font-variant-ligatures:common-ligatures;
    font-weight:400;
    height:24px;
    line-height:24px;
    margin-bottom:8px;
    margin-left:8px;
    margin-right:8px;
    margin-top:8px;
    text-size-adjust:100%;
    vertical-align:middle;
    width:11.9062px;
    -webkit-font-smoothing:antialiased;
  }
`;

export const InlineInput = styled.input`
  background-attachment:scroll;
  background-clip:border-box;
  background-color:rgba(0, 0, 0, 0);
  background-image:none;
  background-origin:padding-box;
  background-position-x:0px;
  background-position-y:0px;
  background-repeat-x:;
  background-repeat-y:;
  background-size:auto;
  border-bottom-color:rgb(29, 28, 29);
  border-bottom-left-radius:4px;
  border-bottom-right-radius:4px;
  border-top-left-radius:4px;
  border-top-right-radius:4px;
  border-bottom-style:none;
  border-bottom-width:0px;
  border-image-outset:0px;
  border-image-repeat:stretch;
  border-image-slice:100%;
  border-image-source:none;
  border-image-width:1;
  border-left-color:rgb(29, 28, 29);
  border-left-style:none;
  border-left-width:0px;
  border-right-color:rgb(29, 28, 29);
  border-right-style:none;
  border-right-width:0px;
  border-top-color:rgb(29, 28, 29);
  border-top-style:none;
  border-top-width:0px;
  box-shadow:none;
  box-sizing:border-box;
  color:rgb(29, 28, 29);
  cursor:text;
  display:inline-block;
  font-size:22px;
  font-stretch:100%;
  font-style:normal;
  font-variant-caps:normal;
  font-variant-east-asian:normal;
  font-variant-ligatures:none;
  font-variant-numeric:normal;
  font-weight:400;
  height:64px;
  letter-spacing:normal;
  line-height:normal;
  margin-bottom:8px;
  margin-left:0px;
  margin-right:0px;
  margin-top:0px;
  max-width:100%;
  outline-color:rgb(29, 28, 29);
  outline-style:none;
  outline-width:0px;
  padding-bottom:12px;
  padding-left:12px;
  padding-right:12px;
  padding-top:12px;
  text-align:center;
  text-indent:0px;
  text-rendering:auto;
  text-shadow:none;
  text-size-adjust:100%;
  text-transform:none;
  transition-delay:0s, 0s;
  transition-duration:0.07s, 0.07s;
  transition-property:box-shadow, border-color;
  transition-timing-function:ease-out, ease-out;
  width:61.6875px;
  word-spacing:0px;
  writing-mode:horizontal-tb;
  -webkit-appearance:none;
  -webkit-font-smoothing:antialiased;
  -webkit-rtl-ordering:logical;
  -webkit-border-image:none;
`;

export const Input = styled.input`
  background-color:rgb(255, 255, 255);
  border-bottom-color:rgb(134, 134, 134);
  border-bottom-left-radius:4px;
  border-bottom-right-radius:4px;
  border-bottom-style:solid;
  border-bottom-width:1px;
  border-image-outset:0px;
  border-image-repeat:stretch;
  border-image-slice:100%;
  border-image-source:none;
  border-image-width:1;
  border-left-color:rgb(134, 134, 134);
  border-left-style:solid;
  border-left-width:1px;
  border-right-color:rgb(134, 134, 134);
  border-right-style:solid;
  border-right-width:1px;
  border-top-color:rgb(134, 134, 134);
  border-top-left-radius:4px;
  border-top-right-radius:4px;
  border-top-style:solid;
  border-top-width:1px;
  box-shadow:none;
  box-sizing:border-box;
  color:rgb(29, 28, 29);
  cursor:text;
  display:inline;
  font-family:Slack-Lato, appleLogo, sans-serif;
  font-size:20px;
  font-stretch:100%;
  font-style:normal;
  font-variant-caps:normal;
  font-variant-east-asian:normal;
  font-variant-ligatures:none;
  font-variant-numeric:normal;
  font-weight:400;
  height:50px;
  letter-spacing:normal;
  line-height:normal;
  margin-bottom:0px;
  margin-left:0px;
  margin-right:8px;
  margin-top: 1rem;
  max-width:100%;
  outline-color:rgb(29, 28, 29);
  outline-style:none;
  outline-width:0px;
  padding-bottom:12px;
  padding-left:12px;
  padding-right:12px;
  padding-top:12px;
  text-align:start;
  text-indent:0px;
  text-rendering:auto;
  text-shadow:none;
  text-transform:none;
  transition-delay:0s, 0s;
  transition-duration:0.07s, 0.07s;
  transition-property:box-shadow, border-color;
  transition-timing-function:ease-out, ease-out;
  vertical-align:baseline;
  width:406px;
  word-spacing:0px;
  writing-mode:horizontal-tb;
  -webkit-appearance:none;
  -webkit-font-smoothing:antialiased;
  -webkit-rtl-ordering:logical;
  -webkit-border-image:none;
`;

export const Button = styled.button`
  align-items:center;
  background-attachment:scroll;
  background-clip:border-box;
  background-color: #3ebb9e;
  background-image:none;
  background-origin:padding-box;
  background-position-x:0%;
  background-position-y:0%;
  background-repeat-x:;
  background-repeat-y:;
  background-size:auto;
  border-bottom-color:rgb(255, 255, 255);
  border-bottom-left-radius:4px;
  border-bottom-right-radius:4px;
  border-bottom-style:none;
  border-bottom-width:0px;
  border-image-outset:0px;
  border-image-repeat:stretch;
  border-image-slice:100%;
  border-image-source:none;
  border-image-width:1;
  border-left-color:rgb(255, 255, 255);
  border-left-style:none;
  border-left-width:0px;
  border-right-color:rgb(255, 255, 255);
  border-right-style:none;
  border-right-width:0px;
  border-top-color:rgb(255, 255, 255);
  border-top-left-radius:4px;
  border-top-right-radius:4px;
  border-top-style:none;
  border-top-width:0px;
  box-sizing:border-box;
  color:rgb(255, 255, 255);
  cursor:pointer;
  display:inline-flex;
  font-family:Slack-Lato, appleLogo, sans-serif;
  font-size:18px;
  font-stretch:100%;
  font-style:normal;
  font-variant-caps:normal;
  font-variant-east-asian:normal;
  font-variant-ligatures:normal;
  font-variant-numeric:normal;
  font-weight:900;
  height:44px;
  justify-content:center;
  letter-spacing:normal;
  line-height:normal;
  margin-left:0px;
  margin-right:0px;
  margin-top:16px;
  max-width:100%;
  min-width:96px;
  outline-color:rgb(255, 255, 255);
  outline-style:none;
  outline-width:0px;
  padding-bottom:3px;
  padding-left:16px;
  padding-right:16px;
  padding-top:0px;
  position:relative;
  text-align:center;
  text-decoration-color:rgb(255, 255, 255);
  text-decoration-line:none;
  text-decoration-style:solid;
  text-indent:0px;
  text-rendering:auto;
  text-shadow:none;
  text-transform:none;
  transition-delay:0s;
  transition-duration:0.08s;
  transition-property:all;
  transition-timing-function:linear;
  user-select:none;
  white-space:nowrap;
  width:406px;
  word-spacing:0px;
  writing-mode:horizontal-tb;
  -webkit-appearance:none;
  -webkit-font-smoothing:antialiased;
  -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
  -webkit-border-image:none;
`;

export const SplitInputItem = styled.div`
  border-bottom-color:rgba(29, 28, 29, 0.5);
  // border-bottom-left-radius:5px;
  // border-top-left-radius:5px;
  border-bottom-style:solid;
  border-bottom-width:1px;
  border-image-outset:0px;
  border-image-repeat:stretch;
  border-image-slice:100%;
  border-image-source:none;
  border-image-width:1;
  border-left-color:rgba(29, 28, 29, 0.5);
  border-left-style:solid;
  border-left-width:${props => !props.left ? '1px' : '0'};
  border-right-color:rgba(29, 28, 29, 0.5);
  border-right-style:solid;
  border-right-width:1px;
  border-top-color:rgba(29, 28, 29, 0.5);
  border-top-style:solid;
  border-top-width:1px;
  box-sizing:border-box;
  color:rgb(29, 28, 29);
  display:block;
  font-size:18px;
  font-variant-ligatures:common-ligatures;
  height:74px;
  line-height:24px;
  text-size-adjust:100%;
  width:63.6875px;
  -webkit-font-smoothing:antialiased;
`;
