import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 15px;
  cursor: pointer;
`;

export const AvGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div`
  background-image: url(https://www.gravatar.com/avatar/b3e51c7….jpg?s=200&d=mm);
  background-position: 50%;
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: auto;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
  color: #49545a;
  font-size: 1rem;
  font-weight: 600;
  
  &:after {
    border-bottom-color:rgb(73, 84, 90);
    border-bottom-style:none;
    border-bottom-width:0px;
    border-left-color:rgba(0, 0, 0, 0);
    border-left-style:solid;
    border-left-width:4.79688px;
    border-right-color:rgba(0, 0, 0, 0);
    border-right-style:solid;
    border-right-width:4.79688px;
    border-top-color:rgb(73, 84, 90);
    border-top-style:solid;
    border-top-width:4.79688px;
    box-sizing:border-box;
    content:"";
    display:block;
    height:4.79688px;
    margin-left:10px;
    vertical-align:4.08px;
    width:9.59375px;
  }
`;
