import React, { Component } from 'react';
import FrameBox from '@/components/FrameBox';

class Conversion extends Component {
  render() {
    return (
      <>
        <FrameBox title="Conversion" isFull={true}>
        </FrameBox>
      </>
    );
  }
}

export default Conversion;
