import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Logo = styled.img`
  margin: 20px;
  margin-right: auto;
`;

export const Button = styled.a`
  background-color: #3ebb9e;
  border: 0;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  padding: 15px;
  text-transform: uppercase;
  width: 100px;
  text-align: center;
  margin-right: 15px;
  
  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;
