import React, { Component } from 'react';
import JSONPretty from 'react-json-pretty';
import FrameBox from '@/components/FrameBox';

import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import { STORE_KEYS } from '@/stores';
import { Label } from './styles';

class TradingView extends Component {
  render() {
    const { userId } = this.props;
    const exJson = "{\"id\":\"" + userId + "\", \"type\": \"convert\", \"c1Amount\": \"500\", \"symbol\": \"PEG-pXBT\", \"btcPrice\": \"11000\"}";
    const exJson2 = "{\"id\":\"" + userId + "\", \"type\": \"convert\", \"c1Amount\": \"20000\", \"symbol\": \"pUSD-PEG\", \"btcPrice\": \"8800\"}";
    const exJson3 = "{\"id\":\"" + userId + "\", \"type\": \"convert\", \"c1Amount\": \"0.15\", \"symbol\": \"pETH-pXBT\", \"btcPrice\": \"9800\"}";
    const exJson4 = "{\"id\":\"" + userId + "\", \"type\": \"transfer\", \"c1Amount\": \"25000\", \"symbol\": \"PEG\", \"fromAddress\": \"FA3KxGh3oiEQetUpepMWSKreT86LVfWbXJcMq8pnLKb2WFct3H17\", \n" +
      "\t\"toAddress\": \"FA21uH2CBzw7kort5QitdraEQJoAZw6TMVahEWuDXGgdhTohbnv4\", \"btcPrice\": \"9800\"}";
    const exJson5 = "{\"id\":\"" + userId + "\", \"type\": \"burn\", \"fctAddress\": \"FA3KxGh3oiEQetUpepMWSKreT86LVfWbXJcMq8pnLKb2WFct3H17\", \"burnAmount\": \"300\", \"btcPrice\": \"9760\"}";

    return (
      <>
        <FrameBox title="TradingView" isFull={true}>
          <div className="row form-group">
            <div className="col-md-4 form-label">Webhook URL</div>
            <div className="col-md-8">
              <input type={'text'} className="form-input" value={'https://ptrader.co/api/tradebot'}/>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-6 offset-md-4">
              <button type="submit" className="btn-submit">Disable</button>
            </div>
          </div>
        </FrameBox>
        <FrameBox title="Alert WebHook Message" isFull={true}>
          <Label>
            TradingView Webhooks allow you to send a POST request to a certain URL every time the alert is triggered.
            Check below examples and set in your TradingView alerts.
          </Label>
          <ul>
            <li>
              <p>Convert 500 PEG to pXBT when BTC price is $11,000</p>
              <JSONPretty id="json-pretty" data={exJson}/>
            </li>
            <li>
              <p>Convert 20,000 pUSD to PEG when BTC price is $8,800</p>
              <JSONPretty id="json-pretty" data={exJson2}/>
            </li>
            <li>
              <p>Convert 0.15 PETH to pXBT when BTC price is $9,800</p>
              <JSONPretty id="json-pretty" data={exJson3}/>
            </li>
            {/*
            <li>
              <p>Transfer 25000 PEG from Address1 to Address2 when BTC price is $9,800</p>
              <JSONPretty id="json-pretty" data={exJson4}/>
            </li>
            */}
            <li>
              <p>Burn 300 FCT when BTC price is $9,760</p>
              <JSONPretty id="json-pretty" data={exJson5}/>
            </li>
          </ul>
        </FrameBox>
      </>
    );
  }
}

export default compose(
  inject(STORE_KEYS.REALDEPOSITSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.REALDEPOSITSTORE]: {
         userId,
       },
     }) => ({
      userId,
    })
  )
)(TradingView);
