import styled from 'styled-components';

export const ContentBox = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: ${props => props.isFull ? '100%' : '66.66%'};
  box-sizing: border-box;
  color: #49545a;
  margin-bottom: 2rem;
  box-shadow: 0 2px 3px rgba(55,63,67,.16);
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f6f8f9;
  background-clip: border-box;
  border: 0 solid rgba(55,63,67,.125);
  border-radius: .25rem;
  
  > .card-header {
    background-color: #fff;
    color:rgb(73, 84, 90);
    display: ${props => props.isHeader ? 'block' : 'none'};
    font-size:16px;
    font-weight:400;
    height:56px;
    line-height:24px;
    overflow-wrap:break-word;
    padding-bottom:16px;
    padding-left:20px;
    padding-right:20px;
    padding-top:16px;
    text-align:left;
    text-size-adjust:100%;
    border-bottom: 0 solid rgba(55,63,67,.125);
  }
  
  > .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    // max-height: 700px;
    // overflow: auto;
    ${props => props.nopadding ? `
      padding: 0;
    ` : `
      padding: 1.25rem;    
    `};
    
    .form-group {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
    }
    
    .form-label {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 500;
      text-align: right!important;
    }
    
    .form-input {
      background-clip:padding-box;
      border-bottom-color:rgb(176, 185, 191);
      border-radius:4px;
      border: 1px solid rgb(176, 185, 191);
      box-shadow:rgb(221, 225, 227) 0px 1px 2px 0px;
      box-sizing:border-box;
      color:rgb(110, 125, 135);
      cursor:text;
      direction:ltr;
      display:block;
      font-size:16px;
      font-stretch:100%;
      font-style:normal;
      font-variant-caps:normal;
      font-variant-east-asian:normal;
      font-variant-ligatures:normal;
      font-variant-numeric:normal;
      font-weight:400;
      height:38px;
      letter-spacing:normal;
      line-height:24px;
      margin-bottom:0px;
      margin-left:0px;
      margin-right:0px;
      margin-top:0px;
      overflow-wrap:break-word;
      overflow-x:visible;
      overflow-y:visible;
      padding-bottom:6px;
      padding-left:12px;
      padding-right:12px;
      padding-top:6px;
      text-align:start;
      text-indent:0px;
      text-rendering:auto;
      text-shadow:none;
      text-size-adjust:100%;
      text-transform:none;
      transition-delay:0s, 0s;
      transition-duration:0.15s, 0.15s;
      transition-property:border-color, box-shadow;
      transition-timing-function:ease-in-out, ease-in-out;
      width:330px;
      word-spacing:0px;
      writing-mode:horizontal-tb;
    }
    
    .form-check-label {
      box-sizing:border-box;
      color:rgb(92, 104, 112);
      direction:ltr;
      display:inline-block;
      font-size:15px;
      font-weight:400;
      height:22px;
      line-height:22.5px;
      margin-bottom:0px;
      overflow-wrap:break-word;
      text-align:left;
      text-size-adjust:100%;
      padding-left: 20px;
    }
    
    .btn-submit {
      align-items:flex-start;
      background-color: #3ebb9e;
      border-radius:4px;
      border: 1px solid #3ebb9e;
      box-shadow:rgb(221, 225, 227) 0px 1px 2px 0px;
      box-sizing:border-box;
      color:rgb(255, 255, 255);
      cursor:pointer;
      direction:ltr;
      display:inline-block;
      font-size:15px;
      font-stretch:100%;
      font-style:normal;
      font-variant-caps:normal;
      font-variant-east-asian:normal;
      font-variant-ligatures:normal;
      font-variant-numeric:normal;
      font-weight:500;
      height:36px;
      letter-spacing:normal;
      line-height:22.5px;
      margin-bottom:0px;
      margin-left:0px;
      margin-right:0px;
      margin-top:0px;
      overflow-wrap:break-word;
      overflow-x:visible;
      overflow-y:visible;
      padding-bottom:6px;
      padding-left:12px;
      padding-right:12px;
      padding-top:6px;
      text-align:center;
      text-indent:0px;
      text-rendering:auto;
      text-shadow:none;
      text-size-adjust:100%;
      text-transform:none;
      transition-delay:0s, 0s, 0s, 0s;
      transition-duration:0.15s, 0.15s, 0.15s, 0.15s;
      transition-property:color, background-color, border-color, box-shadow;
      transition-timing-function:ease-in-out, ease-in-out, ease-in-out, ease-in-out;
      user-select:none;
      vertical-align:middle;
      word-spacing:0px;
      writing-mode:horizontal-tb;
    }
    
    .btn-link {
      background-color:rgba(0, 0, 0, 0);
      border: 1px solid rgba(0, 0, 0, 0);
      border-radius:4px;
      border-bottom-style:solid;
      box-shadow:none;
      box-sizing:border-box;
      color:#3ebb9e;
      cursor:pointer;
      direction:ltr;
      display:inline-block;
      font-size:15px;
      font-weight:400;
      height:36px;
      line-height:22.5px;
      overflow-wrap:nowrap;
      padding:6px 12px 6px 16px;
      text-align:center;
      text-decoration-color:#3ebb9e;
      text-decoration-line:none;
      text-decoration-style:solid;
      text-size-adjust:100%;
      transition-delay:0s, 0s, 0s, 0s;
      transition-duration:0.15s, 0.15s, 0.15s, 0.15s;
      transition-property:color, background-color, border-color, box-shadow;
      transition-timing-function:ease-in-out, ease-in-out, ease-in-out, ease-in-out;
      user-select:none;
      vertical-align:middle;
    }
    
    .btn-link2 {
      color:#3ebb9e;
    }
  }
`;
