import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  // background: #000;
`;

export const OrderBookWrapper = styled.div`
  height: calc(100% - 400px);
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
`;
