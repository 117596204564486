import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import SideMenu from '@/containers/Main/SideMenu';

import Balance from '@/containers/Main/Portfolio/Balance';
import Conversion from '@/containers/Main/Portfolio/Conversion';
import Transfer from '@/containers/Main/Portfolio/Transfer';
import History from '@/containers/Main/Portfolio/History';

import Profile from '@/containers/Main/Settings/Profile';
import Security from '@/containers/Main/Settings/Security';
import API from "@/containers/Main/Settings/API";

import TradingView from '@/containers/Main/Dash/TradingView';
import Arbitrage from "@/containers/Main/Dash/Arbitrage";
import Exchanges from "@/containers/Main/Dash/Exchanges";
import IntuitiveBots from "@/containers/Main/Dash/IntuitiveBots";
import TechnicalIndicators from '@/containers/Main/Dash/TechnicalIndicators';
import SmartOrderRouter from '@/containers/Main/Dash/SmartOrderRouter';

import PaymentMethods from "@/containers/Main/Billing/PaymentMethods";
import OrderHistory from "@/containers/Main/Billing/OrderHistory";

import Dispute from "@/containers/Main/Reports/Dispute";
import Ticket from "@/containers/Main/Reports/Ticket";
import Trade from "@/containers/Main/Trade";

import { STORE_KEYS } from '@/stores';
import { Wrapper } from './styles';

class Main extends Component {
  constructor(props) {
    super(props);
  }

  onChangeView = (id) => {
    const { setViewMode } = this.props;
    setViewMode(id);
  };

  render() {
    const { viewMode: mode, headMode } = this.props;
    let Content = <></>;

    switch (mode) {
      case 'balance':
        Content = <Balance/>;
        break;
      case 'conversion':
        Content = <Conversion/>;
        break;
      case 'transfer':
        Content = <Transfer/>;
        break;
      case 'history':
        Content = <History/>;
        break;
      case 'tradingview':
        Content = <TradingView/>;
        break;
      case 'arbitrage':
        Content = <Arbitrage/>;
        break;
      case 'exchanges':
        Content = <Exchanges/>;
        break;
      case 'intuitive_bots':
        Content = <IntuitiveBots/>;
        break;
      case 'technical_indicators':
        Content = <TechnicalIndicators/>;
        break;
      case 'smart_order_router':
        Content = <SmartOrderRouter/>;
        break;

      case 'profile':
        Content = <Profile/>;
        break;
      case 'security':
        Content = <Security/>;
        break;
      case 'api':
        Content = <API/>;
        break;

      case 'payment_method':
        Content = <PaymentMethods/>;
        break;
      case 'order_history':
        Content = <OrderHistory/>;
        break;

      case 'dispute':
        Content = <Dispute/>;
        break;
      case 'ticket':
        Content = <Ticket/>;
        break;
      default:
        Content = <></>;
        break;
    }

    return headMode !== 'TRADE' ? (
      <Wrapper className="row">
        <div className="col-md-3">
          <SideMenu onChangeView={this.onChangeView} mode={mode}/>
        </div>
        <div className="col-md-9">
          {Content}
        </div>
      </Wrapper>
    ) : (
      <Trade/>
    );
  }
}

export default compose(
  inject(STORE_KEYS.VIEWMODESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.VIEWMODESTORE]: { setViewMode, viewMode, headMode },
     }) => ({
      setViewMode,
      viewMode,
      headMode,
    })
  )
)(Main);
