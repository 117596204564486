import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 63px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(55,63,67,.04);
`;

export const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 16px;
  
  .menu {
    color: #000;
    color: #656565;
    list-style: none;
    font-size: 14px;
    
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-left: auto;
    padding-top: 2px;
    
    .menu_item {
      padding: 0 10.5px;
      letter-spacing: 0.4px;
      cursor: pointer;
      
      &.active {
        color: rgb(62, 187, 158);
        font-weight: bold;
      }
      
      &:hover {
        color: rgb(62, 187, 158);
      }
    }
  }
`;

export const NotifyPill = styled.a`
  align-items:center;
  background-color:rgb(244, 245, 246);
  border-bottom-left-radius:99px;
  border-bottom-right-radius:99px;
  border-top-left-radius:99px;
  border-top-right-radius:99px;
  box-sizing:border-box;
  color:rgb(73, 84, 90) !important;
  cursor:pointer;
  direction:ltr;
  display:flex;
  font-size:15px;
  font-weight:600;
  height:34px;
  justify-content:center;
  line-height:22.5px;
  margin-bottom:0px;
  margin-right:0px;
  margin-left: 20px;
  text-align:left;
  text-decoration-color:rgb(73, 84, 90);
  text-decoration-line:none;
  text-decoration-style:solid;
  text-size-adjust:100%;
  width:71px;
  -webkit-box-align:center;
  -webkit-box-direction:normal;
  -webkit-box-pack:center;
  -webkit-tap-highlight-color:rgba(55, 63, 67, 0);
`;

export const Logo = styled.img`
  height: 50px;
`;

export const UL = styled.div`
  margin-left: auto;
  box-sizing:border-box;
  color:rgb(73, 84, 90);
  direction:ltr;
  display:flex;
  flex-direction:row;
  font-size:16px;
  font-weight:400;
  height:34px;
  line-height:24px;
  list-style-image:none;
  list-style-position:outside;
  list-style-type:none;
  text-align:left;
  text-size-adjust:100%;
  
  > li {
    box-sizing:border-box;
    color:rgb(73, 84, 90);
    direction:ltr;
    display:list-item;
    font-size:16px;
    font-weight:400;
    height:34px;
    line-height:24px;
    list-style-image:none;
    list-style-position:outside;
    list-style-type:none;
    text-align:left;
    text-size-adjust:100%;
    
    > a {
      color: #49545a;
      display: flex;
      align-items: center;
      padding: 5px .5rem;
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;
