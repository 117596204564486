import React from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/styles/makeStyles';

import { Wrapper, Avatar, Label, AvGroup } from './styles';
import { STORE_KEYS } from '@/stores';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5.5),
    backgroundColor: '#fff',
    color: '#49545a'
  },
  item: {
    '&:hover': {
      backgroundColor: '#f4f5f6',
      fontWeight: 400,
      fontSize: '1rem'
    }
  }
}));

const AvatarMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const { logout, name, setViewMode } = props;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openProfile = () => {
    setViewMode('profile');
    handleClose();
  };

  const openSecurity = () => {
    setViewMode('security');
    handleClose();
  };

  return (
    <Wrapper>
      <AvGroup onClick={handleClick}>
        <Avatar/>
        <Label>{name}</Label>
      </AvGroup>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
      >
        <MenuItem onClick={openProfile} className={classes.item}>Profile</MenuItem>
        <MenuItem onClick={openSecurity} className={classes.item}>Security</MenuItem>
        <MenuItem onClick={logout} className={classes.item}>Logout</MenuItem>
      </Menu>
    </Wrapper>
  );
};

export default compose(
  inject(STORE_KEYS.AUTHSTORE, STORE_KEYS.VIEWMODESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: { logout },
       [STORE_KEYS.VIEWMODESTORE]: { setViewMode },
     }) => ({
      logout,
      setViewMode,
    })
  )
)(AvatarMenu);
