import React from 'react';

import PriceChartLive from './PriceChartLive';
import { ChartWrapper } from './styles';

const PriceChartCanvas = () => {
  return (
    <ChartWrapper>
      <PriceChartLive baseSymbol='BTC'
                      quoteSymbol='PEG'
                      avgPrice={8000}
      />
    </ChartWrapper>
  );
};

export default PriceChartCanvas;
