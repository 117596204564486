import React, { Component } from 'react';
import FrameBox from '@/components/FrameBox';

class OrderHistory extends Component {
  render() {
    return (
      <>
        <FrameBox title="Order History" isFull={true}>
        </FrameBox>
      </>
    );
  }
}

export default OrderHistory;
