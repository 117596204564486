import React from 'react';
import { action, observable, reaction } from 'mobx';

import {
  DepositAddressRequest,
  WithdrawCoinRequest,
  ConversionRequest,
  RealWithdrawConfirm,
} from '@/lib/ws';

class RealDepositStore {
  @observable userId = '';
  @observable fctAddr = '';
  @observable priceUpdatedAt = '';
  @observable myBalances = {};

  isLoggedIn = false;

  constructor(authStore, snackbar, coinPriceStore) {
    this.isLoggedIn = authStore.isLoggedIn;
    this.snackbar = snackbar;

    if (this.isLoggedIn) {
      this.getDepositAddresses();
    }

    reaction(
      () => ({
        isLoggedIn: authStore.isLoggedIn
      }),
      authObj => {
        this.isLoggedIn = authObj.isLoggedIn;
        console.log("authObj.isLoggedIn:", authObj.isLoggedIn);
        if (this.isLoggedIn) {
          this.getDepositAddresses();
        }
      }
    );

    reaction(
      () => ({
        maketPriceData: coinPriceStore.maketPriceData
      }),
      (marketObj) => {
        this.priceUpdatedAt = (new Date()).getTime();
      }
    );
  }

  getDepositAddresses = () => {
    DepositAddressRequest({})
      .then(data => {
        if (!data.error) {
          this.userId = data.userId || '';
          this.fctAddr = data.address;
          this.myBalances = {
            PEG: data.PEG || 0,
            pADA: data.pADA || 0,
            pATOM: data.pATOM || 0,
            pAUD: data.pAUD || 0,
            pBAT: data.pBAT || 0,
            pBNB: data.pBNB || 0,
            pBRL: data.pBRL || 0,
            pCAD: data.pCAD || 0,
            pCHF: data.pCHF || 0,
            pCNY: data.pCNY || 0,
            pDASH: data.pDASH || 0,
            pDCR: data.pDCR || 0,
            pEOS: data.pEOS || 0,
            pETH: data.pETH || 0,
            pEUR: data.pEUR || 0,
            pFCT: data.pFCT || 0,
            pGBP: data.pGBP || 0,
            pHKD: data.pHKD || 0,
            pINR: data.pINR || 0,
            pJPY: data.pJPY || 0,
            pKRW: data.pKRW || 0,
            pLINK: data.pLINK || 0,
            pLTC: data.pLTC || 0,
            pMXN: data.pMXN || 0,
            pNOK: data.pNOK || 0,
            pNZD: data.pNZD || 0,
            pPHP: data.pPHP || 0,
            pRUB: data.pRUB || 0,
            pRVN: data.pRVN || 0,
            pSEK: data.pSEK || 0,
            pSGD: data.pSGD || 0,
            pTRY: data.pTRY || 0,
            pUSD: data.pUSD || 0,
            pXAG: data.pXAG || 0,
            pXAU: data.pXAU || 0,
            pXBC: data.pXBC || 0,
            pXBT: data.pXBT || 0,
            pXLM: data.pXLM || 0,
            pXMR: data.pXMR || 0,
            pXTZ: data.pXTZ || 0,
            pZAR: data.pZAR || 0,
            pZEC: data.pZEC || 0,
          };
        } else {
          console.log('getWAddress: ', data.error);
          this.fctAddr = '';
        }
      })
      .catch(err => {
        console.log('getWAddress: ', err);
        this.fctAddr = '';
      });
  };

  @action.bound withdrawConfirm(token, inputCode) {
    return new Promise((resolve, reject) => {
      RealWithdrawConfirm({
        token,
        tfaCode: inputCode
      })
        .then(data => {
          if (!data.error) {
            this.showSnackMsg(data.message);
          } else {
            this.showSnackMsg( data.error);
          }
          resolve(true);
        });
    });
  }

  @action.bound sendCoin(amount, address, symbol) {
      return new Promise((resolve, reject) => {
        if((this.myBalances[symbol] || 0) <= 0) {
          this.showSnackMsg(`Your ${symbol} balance is insufficient.`);
        } else if (amount <= 0) {
          this.showSnackMsg("Please input more than 0 amount.");
        } else if (address === '') {
          this.showSnackMsg("Please input withdrawal address.");
        } else{
          WithdrawCoinRequest({
            amount, address, symbol,
          })
            .then(data => {
              if (!data.error) {
                this.showSnackMsg(data.message);
              } else {
                this.showSnackMsg( "Withdraw request is failed.");
              }
              resolve(true);
            });
        }
      });
  }

  @action.bound convertCoin(amount, symbol, to) {
    if((this.myBalances[symbol] || 0) <= 0) {
      this.showSnackMsg(`Your ${symbol} balance is insufficient.`);
    } else if (amount <= 0) {
      this.showSnackMsg("Please input more than 0 amount.");
    } else if (to === '') {
      this.showSnackMsg("Please select target coin.");
    } else{
      console.log(amount, symbol, to);
      ConversionRequest({
        amount,
        from: symbol,
        to
      })
        .then(data => {
          if (!data.error) {
            this.showSnackMsg(data.message);
          } else {
            this.showSnackMsg( "Conversion request is failed.");
          }
        });
    }
  }

  @action.bound showSnackMsg(msg) {
    this.snackbar({
      message: () => (
        <>
          <span>
            <b>{msg}</b>
          </span>
        </>
      )
    });
  }
}

export default (authStore, snackbar, coinPriceStore) => {
  const store = new RealDepositStore(authStore, snackbar, coinPriceStore);
  return store;
};
