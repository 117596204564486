import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';

import ActionPopup from '../ActionPopup';
import { Bal, Icon, Item, Label } from "../styles";
import { STORE_KEYS } from '@/stores';
import { commafyDigitFormat } from "@/utils";

const options = [
  'Deposit',
  'Withdraw',
  'Resync',
  'Hide',
];

const useStyles = makeStyles({
  menu: {
    padding: '0',
    color: 'rgb(73,84,90)',
    marginRight: '-10px',
  },
  icon: {
    outline: 'none',
  }
});

const ITEM_HEIGHT = 48;

function CoinItem(props) {
  const {
    name, symbol, icon, myBalances, removeCoin, getCoinPrice, priceUpdatedAt,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dlgMode, setDlgMode] = React.useState('');
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = mode => {
    setDlgMode(mode);
  };

  const handleMenuClick = (value) => {
    switch (value) {
      case 'Deposit':
        handleMenu('Deposit');
        break;
      case 'Withdraw':
        handleMenu('Withdraw');
        break;
      case 'Resync':
        break;
      case 'Hide':
        removeCoin(symbol);
        break;
      default:
        break;
    }
    handleClose();
  };

  const balance = (myBalances[symbol] || 0) / 100000000;
  const price = balance > 0 ? getCoinPrice(symbol) : 0;

  return (
    <div>
      <Item>
        <Icon name={icon}/>
        <Label>
          <div>{name}</div>
          <span>{symbol}</span>
        </Label>
        <ActionPopup
          symbol={symbol}
          name={name}
          icon={icon}
          dlgMode={dlgMode}
          setDlgMode={handleMenu}
        />
        <Bal enabled={balance > 0}>
          <span>{commafyDigitFormat(balance, 8)}</span>
          <span className="usd_value">${commafyDigitFormat(price * balance, 3)}</span>
        </Bal>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.menu}
        >
          <MoreVertIcon className={classes.icon} />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200,
            },
          }}
        >
          {options.map(option => (
            <MenuItem key={option} onClick={() => handleMenuClick(option)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Item>
    </div>
  );
}

export default compose(
  inject(
    STORE_KEYS.REALDEPOSITSTORE,
    STORE_KEYS.COINSHANDLESTORE,
    STORE_KEYS.COINPRICESTORE,
  ),
  observer,
  withProps(
    ({
       [STORE_KEYS.REALDEPOSITSTORE]: { fctAddr, myBalances, priceUpdatedAt },
       [STORE_KEYS.COINSHANDLESTORE]: { removeCoin },
       [STORE_KEYS.COINPRICESTORE]: { getCoinPrice },
     }) => ({
      fctAddr,
      myBalances,
      priceUpdatedAt,
      removeCoin,
      getCoinPrice,
    })
  )
)(CoinItem);
