import React, { Component } from 'react';
import FrameBox from '@/components/FrameBox';

class PaymentMethods extends Component {
  render() {
    return (
      <>
        <FrameBox title="Payment Methods" isFull={true}>
        </FrameBox>
      </>
    );
  }
}

export default PaymentMethods;
