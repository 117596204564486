import React, { Component } from 'react';
import { Wrapper, ASide, NavHeading, UL, LI } from './styles';
import {
  SvgProfileIcon, SvgSecurityIcon, SvgAPIIcon, SvgSubscriptionIcon, SvgPaymentIcon, SvgInvoiceIcon, SvgWalletIcon,
  SvgTransactionIcon, SvgActivityIcon, SvgFacialIcon, SvgVoiceIcon, SvgIrisIcon, SvgFingerIcon, SvgHostIcon, SvgDomainIcon,
  SvgSearchIcon
} from "@/components/SvgIcon";

class SideMenu extends Component {
  render() {
    const { onChangeView, mode } = this.props;

    return (
      <Wrapper>
        <ASide>
          <NavHeading>Dashboard</NavHeading>
          <UL>
            <LI onClick={() => onChangeView('balance')} active={mode === 'balance'}>
              <a href={'#'}><SvgWalletIcon/><span>My Wallets</span></a>
            </LI>
            {/*
            <LI onClick={() => onChangeView('transfer')} active={mode === 'transfer'}>
              <a href={'#'}><SvgInvoiceIcon/><span>Buy / Sell</span></a>
            </LI>
            */}
            <LI onClick={() => onChangeView('history')} active={mode === 'history'}>
              <a href={'#'}><SvgTransactionIcon/><span>History</span></a>
            </LI>
            <LI onClick={() => onChangeView('exchanges')} active={mode === 'exchanges'}>
              <a href={'#'}><SvgHostIcon/><span>Exchanges</span></a>
            </LI>
          </UL>
        </ASide>
        <ASide>
          <NavHeading>Trading Bots</NavHeading>
          <UL>
            <LI onClick={() => onChangeView('tradingview')} active={mode === 'tradingview'}>
              <a href={'#'}><SvgSearchIcon/><span>TradingView Script</span></a>
            </LI>
            <LI onClick={() => onChangeView('arbitrage')} active={mode === 'arbitrage'}>
              <a href={'#'}><SvgHostIcon/><span>Arbitrage</span></a>
            </LI>
            {/*
            <LI onClick={() => onChangeView('smart_order_router')} active={mode === 'smart_order_router'}>
              <a href={'#'}><SvgDomainIcon/><span>Smart Order Router</span></a>
            </LI>
            <LI onClick={() => onChangeView('api')} active={mode === 'api'}>
              <a href={'#'}><SvgAPIIcon/><span>API Connect</span></a>
            </LI>
            */}
          </UL>
        </ASide>
        <ASide>
          <NavHeading>Settings</NavHeading>
          <UL>
            <LI onClick={() => onChangeView('profile')} active={mode === 'profile'}>
              <a href={'#'}><SvgProfileIcon/><span>Profile</span></a>
            </LI>
            <LI onClick={() => onChangeView('security')} active={mode === 'security'}>
              <a href={'#'}><SvgSecurityIcon/><span>Security</span></a>
            </LI>
          </UL>
        </ASide>
        {/*
        <ASide>
          <NavHeading>Billing</NavHeading>
          <UL>
            <LI onClick={() => onChangeView('payment_method')} active={mode === 'payment_method'}>
              <a href={'#'}><SvgWalletIcon/><span>Payment methods</span></a>
            </LI>
            <LI onClick={() => onChangeView('order_history')} active={mode === 'order_history'}>
              <a href={'#'}><SvgTransactionIcon/><span>Order History</span></a>
            </LI>
          </UL>
        </ASide>
        <ASide>
          <NavHeading>Reports</NavHeading>
          <UL>
            <LI onClick={() => onChangeView('dispute')} active={mode === 'dispute'}>
              <a href={'#'}><SvgSubscriptionIcon/><span>Disputes</span></a>
            </LI>
            <LI onClick={() => onChangeView('ticket')} active={mode === 'ticket'}>
              <a href={'#'}><SvgPaymentIcon/><span>Tickets</span></a>
            </LI>
          </UL>
        </ASide>
        */}
      </Wrapper>
    );
  }
}

export default SideMenu;
