import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import AvatarMenu from '@/components/AvatarMenu';
import { STORE_KEYS } from '@/stores';
import Notifications from "./Notification";
import {
  Wrapper, InnerWrapper, Logo, UL
} from './styles';

class Header extends Component {
  componentDidMount() {

  }

  onTrade = () => {
    this.props.setViewMode('');
    this.props.setHeadMode('TRADE');
  };

  onWallet = () => {
    this.props.setViewMode('balance');
  };

  onBots = () => {
    this.props.setViewMode('tradingview');
  };

  onSettings = () => {
    this.props.setViewMode('profile');
  };

  render() {
    const { isLoggedIn, email, headMode } = this.props;
    const name = (email || '').split('@')[0];

    return (
      <Wrapper>
        <InnerWrapper>
          <a href="/"><Logo src={'/assets/img/logo.png'} /></a>
          {isLoggedIn ? (
            <>
              <ul className="menu">
                <li className={`menu_item ${headMode === 'TRADE' ? 'active' : ''}`} onClick={this.onTrade}>TRADE</li>
                <li className={`menu_item ${headMode === 'WALLET' ? 'active' : ''}`} onClick={this.onWallet}>WALLET</li>
                <li className={`menu_item ${headMode === 'BOT' ? 'active' : ''}`} onClick={this.onBots}>BOTS</li>
                <li className={`menu_item ${headMode === 'SETTINGS' ? 'active' : ''}`} onClick={this.onSettings}>SETTINGS</li>
              </ul>
              <Notifications/>
              <AvatarMenu name={name}/>
            </>
          ) : (
            <UL>
              <li><a href={'/login'}>Sign In</a></li>
              <li><a href={'/register'}>Register</a></li>
            </UL>
          )}
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default compose(
  inject(STORE_KEYS.AUTHSTORE, STORE_KEYS.VIEWMODESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: { isLoggedIn, email },
       [STORE_KEYS.VIEWMODESTORE]: { viewMode, headMode, setViewMode, setHeadMode },
     }) => ({
      isLoggedIn,
      email,
      viewMode,
      headMode,
      setViewMode,
      setHeadMode,
    })
  )
)(Header);

