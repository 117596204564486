import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';

import FrameBox from '@/components/FrameBox';
import { STORE_KEYS } from '@/stores';

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };
  }

  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value || '',
    });
  };

  onSubmitReset = () => {
    const { email } = this.state;
    const { showSnackMsg, resetPasswordRequest } = this.props;

    if ((email || '').trim().length === 0) {
      showSnackMsg('Please input email correctly.');
      return;
    }

    resetPasswordRequest(email);
  };

  render() {
    const { email } = this.state;

    return (
      <FrameBox title={'Reset Password'}>
        <div className="row form-group">
          <div className="col-md-4 form-label">E-Mail Address</div>
          <div className="col-md-8">
            <input type={'text'} className="form-input" value={email} onChange={this.onChangeEmail}/>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4"/>
          <div className="col-md-8">
            If you enabled 2FA already before, you can reset your password. Otherwise, you can not reset your password.
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-6 offset-md-4">
            <button type="submit" className="btn-submit" onClick={this.onSubmitReset}>Send Password Reset Link</button>
          </div>
        </div>
      </FrameBox>
    );
  }
}

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: { showSnackMsg, resetPasswordRequest },
     }) => ({
      showSnackMsg, resetPasswordRequest
    })
  )
)(PasswordReset);
