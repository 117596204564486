import React from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";

import { Wrapper } from './styles';
import { STORE_KEYS } from '@/stores';
import { commafyDigitFormat } from "@/utils";

const useStyles = makeStyles({
  input: {
    width: '100%',
    marginBottom: '15px',
  },
});

function Withdraw(props) {
  const { fctAddr, myBalances, symbol, is_tfa } = props;
  const classes = useStyles();
  const [confirm, setConfirm] = React.useState(false);
  const [inputCode, setInputCode] = React.useState('');
  const [confirmCode, setConfirmCode] = React.useState('');
  const [amount, setAmount] = React.useState(0);
  const [address, setAddress] = React.useState('');
  const balance = (myBalances[symbol] || 0) / 100000000;

  const handleChangeAmount = event => {
    setAmount(event.target.value);
  };

  const handleChangeAddress = event => {
    setAddress(event.target.value);
  };

  const handleWithdrawSubmit = () => {
    const { sendCoin, symbol } = props;
    sendCoin(amount, address, symbol)
      .then(() => {
        setConfirm(true);
      });
  };

  const handleChangeConfirmCode = event => {
    setConfirmCode(event.target.value);
  };

  const handleConfirmCodeSubmit = () => {
    const { withdrawConfirm } = props;
    withdrawConfirm(confirmCode, inputCode);
  };

  const handleChange2FACode = (event) => {
    setInputCode(event.target.value);
  };

  return (
    <Wrapper>
      {!confirm ? (
        <div>
          <TextField
            label="Withdraw from"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            value={fctAddr}
            className={classes.input}
          />
          <TextField
            label="Amount*"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            className={classes.input}
            value={amount}
            onChange={handleChangeAmount}
          />
          <TextField
            label="Withdraw to*"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            className={classes.input}
            value={address}
            onChange={handleChangeAddress}
          />
          {!is_tfa && (
            <div className="recommend">
              * Please enable Two-step verification from security page to prevent unauthorized access to your wallet. (recommendation)
            </div>
          )}
          <div className="btn_wrapper">
            <button className="btn_send" onClick={handleWithdrawSubmit}>Withdraw</button>
          </div>
        </div>
      ) : (
        <div>
          <h3 className="title_confirm">Email Confirmation</h3>
          <TextField
            label="Enter confirmation codes"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={confirmCode}
            className={classes.input}
            onChange={handleChangeConfirmCode}
          />
          {is_tfa && (
            <>
              <br/><br/>
              <h3 className="title_confirm">Google 2FA Authentication</h3>
              <TextField
                label="Enter 2FA codes"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={inputCode}
                onChange={handleChange2FACode}
                className={classes.input}
              />
            </>
          )}
          <br/><br/>
          <div className="btn_wrapper">
            <button className="btn_send" onClick={handleConfirmCodeSubmit}>Confirm</button>
          </div>
        </div>
      )}
      <span className="warning">balance: {commafyDigitFormat(balance, 8)} {symbol}</span>
    </Wrapper>
  );
}

export default compose(
  inject(STORE_KEYS.REALDEPOSITSTORE, STORE_KEYS.SECURITYSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.REALDEPOSITSTORE]: {
         fctAddr, myBalances, sendCoin, withdrawConfirm,
       },
       [STORE_KEYS.SECURITYSTORE]: {
         is_tfa,
       }
     }) => ({
      fctAddr,
      myBalances,
      sendCoin,
      withdrawConfirm,
      is_tfa,
    })
  )
)(Withdraw);
