import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 500px;
  text-align: center;
  padding-top: 20px;
  
  .warning {
    position: absolute;
    top: 70px;
    right: 16px;
    font-size: 15px;
    font-weight: bold;
  }
  
  .btn_wrapper {
    text-align: center;
  }
  
  .btn_send {
    align-items:flex-start;
    background-color:#3ebb9e;
    border-radius:4px;
    border: 1px solid #3ebb9e;
    box-shadow:rgb(221, 225, 227) 0px 1px 2px 0px;
    box-sizing:border-box;
    color:rgb(255, 255, 255);
    cursor:pointer;
    direction:ltr;
    display:inline-block;
    font-size:15px;
    font-stretch:100%;
    font-style:normal;
    font-variant-caps:normal;
    font-variant-east-asian:normal;
    font-variant-ligatures:normal;
    font-variant-numeric:normal;
    font-weight:500;
    width: 150px;
    height: 40px;
    letter-spacing:normal;
    line-height:22.5px;
    margin-bottom:0px;
    margin-left:0px;
    margin-right:0px;
    margin-top:15px;
    overflow-wrap:break-word;
    overflow-x:visible;
    overflow-y:visible;
    padding-bottom:6px;
    padding-left:12px;
    padding-right:12px;
    padding-top:6px;
    text-align:center;
    text-indent:0px;
    text-rendering:auto;
    text-shadow:none;
    text-size-adjust:100%;
    text-transform:none;
    transition-delay:0s, 0s, 0s, 0s;
    transition-duration:0.15s, 0.15s, 0.15s, 0.15s;
    transition-property:color, background-color, border-color, box-shadow;
    transition-timing-function:ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    user-select:none;
    vertical-align:middle;
    word-spacing:0px;
    writing-mode:horizontal-tb;
  }
`;

export const Label = styled.div`
  font-size: 12px;
  color: #555;
  text-align: left;
`;

export const LabelEst = styled.div`
  font-size: 16px;
  color: #555;
  text-align: center;
  padding-top: 10px;
  
  > span {
    color: #da1b1b;
  }
`;
