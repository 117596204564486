import React, { Component } from 'react';
import FrameBox from '@/components/FrameBox';

class UpdatePass extends Component {
  render() {
    return (
      <FrameBox title="Update Password" isFull={true}>
        <div className="row form-group">
          <div className="col-md-4 form-label">Current Password</div>
          <div className="col-md-8">
            <input type={'password'} className="form-input"/>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4 form-label">New Password</div>
          <div className="col-md-8">
            <input type={'password'} className="form-input"/>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4 form-label">Confirm New Password</div>
          <div className="col-md-8">
            <input type={'password'} className="form-input"/>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-6 offset-md-4">
            <button type="submit" className="btn-submit">Update</button>
          </div>
        </div>
      </FrameBox>
    )
  }
}

export default UpdatePass;
