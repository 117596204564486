import React, { Component } from 'react';
import FrameBox from '@/components/FrameBox';
import imgHigh from './assets/img_high.png';
import imgLow from './assets/img_low.png';

import { Wrapper } from './styles';

class Arbitrage extends Component {

  render() {

    return (
      <Wrapper>
        <FrameBox title="Arbitrage of High pAsset Prices" isFull={true}>
          <img src={imgHigh} alt="" className="img_strategy" />
        </FrameBox>
        <FrameBox title="Arbitrage of Low pAsset Prices" isFull={true}>
          <img src={imgLow} alt="" className="img_strategy" />
        </FrameBox>
      </Wrapper>
    );
  }
}

export default Arbitrage;
