import styled from "styled-components";

export const Wrapper = styled.div`
  .container {
    display: flex;
    padding: 0;
  }
  
  .inputContainer {
    flex: 1;
    border: 1px solid #cccccc;
  }
  
  .inputContainer:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .inputContainer:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  
  .spacer {
    flex: 0.3 0;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 24px;
  }
  
  .input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 24px;
    text-align: center;
    border: none;
    outline: none;
    border-radius: 5px;
    box-sizing: border-box;
  }
`;
