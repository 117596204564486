import React from 'react';
import { action, observable, reaction } from "mobx";

import {
  AccountSettingsRequest, ChangeCoinRequest,
} from '@/lib/ws';

export const pAssetCoins = [
  {
    name: 'PegNet',
    symbol: 'PEG',
    icon: 'icon_coin_peg.svg',
  },
  {
    name: 'Bitcoin',
    symbol: 'pXBT',
    icon: 'icon_coin_pbtc.svg',
  },
  {
    name: 'US Dollar',
    symbol: 'pUSD',
    icon: 'icon_curr_usdollar.svg',
  },
  {
    name: 'Factom',
    symbol: 'pFCT',
    icon: 'icon_coin_factom.svg',
  },
  {
    name: 'Gold Troy Ounce',
    symbol: 'pXAU',
    icon: 'icon_com_gold.svg',
  },
  {
    name: 'Ethereum',
    symbol: 'pETH',
    icon: 'icon_coin_peth.svg',
  },
  {
    name: 'Tezos',
    symbol: 'pXTZ',
    icon: 'icon_coin_tezos.svg',
  },
  {
    name: 'Decred',
    symbol: 'pDCR',
    icon: 'icon_coin_decred.svg',
  },
  {
    name: 'Bitcoin Cash',
    symbol: 'pXBC',
    icon: 'icon_coin_bch.svg',
  },
  {
    name: 'Dash',
    symbol: 'pDASH',
    icon: 'icon_coin_dash.svg',
  },
  {
    name: 'Chainlink',
    symbol: 'pLINK',
    icon: 'icon_coin_chainlink.svg',
  },
  {
    name: 'Silver Troy Ounce',
    symbol: 'pXAG',
    icon: 'icon_com_silver.svg',
  },
  {
    name: 'Korean Won',
    symbol: 'pKRW',
    icon: 'icon_curr_won.svg',
  },
  {
    name: 'Binance Coin',
    symbol: 'pBNB',
    icon: 'icon_coin_binancecoin.svg',
  },
  {
    name: 'Cosmos',
    symbol: 'pATOM',
    icon: 'icon_coin_atom.svg',
  },
  {
    name: 'Zcash',
    symbol: 'pZEC',
    icon: 'icon_coin_zcash.svg',
  },
  {
    name: 'Cardano',
    symbol: 'pADA',
    icon: 'icon_coin_cardano.svg',
  },
  {
    name: 'Litecoin',
    symbol: 'pLTC',
    icon: 'icon_coin_litecoin.svg',
  },
  {
    name: 'Monero',
    symbol: 'pXMR',
    icon: 'icon_coin_monero.svg',
  },
  {
    name: 'Stellar',
    symbol: 'pXLM',
    icon: 'icon_coin_stellar.svg',
  },
  {
    name: 'Ravencoin',
    symbol: 'pRVN',
    icon: 'icon_coin_raven.svg',
  },
  {
    name: 'Euro',
    symbol: 'pEUR',
    icon: 'icon_curr_euro.svg',
  },
  {
    name: 'Chinese Yuan',
    symbol: 'pCNY',
    icon: 'icon_curr_yuan.svg',
  },
  {
    name: 'Pound Sterling',
    symbol: 'pGBP',
    icon: 'icon_curr_pound.svg',
  },
  {
    name: 'Swiss Franc',
    symbol: 'pCHF',
    icon: 'icon_curr_swissfranc.svg',
  },
  {
    name: 'Mexican Peso',
    symbol: 'pMXN',
    icon: 'icon_curr_peso.svg',
  },
  {
    name: 'Hong Kong Dollar',
    symbol: 'pHKD',
    icon: 'icon_curr_hkdollar.svg',
  },
  {
    name: 'Philippine Peso',
    symbol: 'pPHP',
    icon: 'icon_curr_philepinepeso.svg',
  },
  {
    name: 'Japanese Yen',
    symbol: 'pJPY',
    icon: 'icon_curr_yen.svg',
  },
  {
    name: 'Indian Rupee',
    symbol: 'pINR',
    icon: 'icon_curr_rupee.svg',
  },
  {
    name: 'Canadian Dollar',
    symbol: 'pCAD',
    icon: 'icon_curr_candollar.svg',
  },
  {
    name: 'Singapore Dollar',
    symbol: 'pSGD',
    icon: 'icon_curr_singaporedollar.svg',
  },
  {
    name: 'Brazil Real',
    symbol: 'pBRL',
    icon: 'icon_curr_brazilreal.svg',
  },
  {
    name: 'New Zealand Dollar',
    symbol: 'pNZD',
    icon: 'icon_curr_newzealanddollar.svg',
  },
  {
    name: 'Norwegian Krone',
    symbol: 'pNOK',
    icon: 'icon_curr_norwegiankrone.svg',
  },
  {
    name: 'Russian Ruble',
    symbol: 'pRUB',
    icon: 'icon_curr_ruble.svg',
  },
  {
    name: 'Swedish Krona',
    symbol: 'pSEK',
    icon: 'icon_curr_swedishkrona.svg',
  },
  // {
  //   name: 'Turkish Lira',
  //   symbol: 'pTRY',
  //   icon: 'icon_curr_turkishlira.svg',
  // },
  {
    name: 'Basic Attention Token',
    symbol: 'pBAT',
    icon: 'icon_coin_basicattentiontoken.svg',
  },
  {
    name: 'EOS',
    symbol: 'pEOS',
    icon: 'icon_coin_eos.svg',
  },
  {
    name: 'Australian Dollar',
    symbol: 'pAUD',
    icon: 'icon_curr_ausdollar.svg',
  },
  {
    name: 'South African Rand',
    symbol: 'pZAR',
    icon: 'icon_curr_southafricanrand.svg',
  },
];

class CoinsHandleStore {
  @observable activeCoins = {};
  @observable selectCoin = '';

  isLoggedIn = false;

  constructor(authStore, snackbar) {
    this.isLoggedIn = authStore.isLoggedIn;
    this.snackbar = snackbar;

    if (this.isLoggedIn) {
      this.getAccountSettings();
    }

    reaction(
      () => ({
        isLoggedIn: authStore.isLoggedIn
      }),
      authObj => {
        this.isLoggedIn = authObj.isLoggedIn;
        console.log("authObj.isLoggedIn:", authObj.isLoggedIn);
        if (this.isLoggedIn) {
          this.getAccountSettings();
        }
      }
    );
  }

  getCoinElement(coin) {
    for (let i = 0; i < pAssetCoins.length; i++) {
      if (pAssetCoins[i].symbol === coin) {
        return pAssetCoins[i];
      }
    }
  }

  getAccountSettings = () => {
    AccountSettingsRequest({})
      .then(data => {
        if (!data.error) {
          this.activeCoins = {};

          const assets = data.assets || {};
          const keys = Object.keys(assets);
          const values = Object.values(assets);

          for (let i = 0; i < keys.length; i++) {
            if (values[i]) {
              this.activeCoins[keys[i]] = this.getCoinElement(keys[i]);
            }
          }
          this.selectCoin = (new Date()).getTime();
        } else {
          console.log('settings: ', data.error);
        }
      })
      .catch(err => {
        console.log('settings: ', err);
      });
  };

  @action.bound addCoin(value) {
    const coin = value.split(' - ')[0];
    pAssetCoins.map((element, index) => {
      if (element.symbol === coin) {
        this.activeCoins[element.symbol] = element;
        this.selectCoin = element.symbol + (new Date()).getTime();
      }
    });
    ChangeCoinRequest({ coin, mode: true });
  }

  @action.bound removeCoin(coin) {
    try {
      delete this.activeCoins[coin];
      this.selectCoin = coin + (new Date()).getTime();
      ChangeCoinRequest({ coin, mode: false });
    } catch (e) {}
  }

  @action.bound showSnackMsg(msg) {
    this.snackbar({
      message: () => (
        <>
          <span>
            <b>{msg}</b>
          </span>
        </>
      )
    });
  }
}

export default (authStore, snackbar) => {
  const store = new CoinsHandleStore(authStore, snackbar);
  return store;
};
