import React from 'react';
import { observable, action } from 'mobx';
import {
  loginWithEmail, registerWithEmail, confirmEmail, existEmail, resetPasswordRequest, resetPassword
} from "@/lib/rest";
import { getSCRealObservable } from "@/lib/ws";

class AuthStore {
  @observable isLoggedIn = false;
  @observable email = '';
  @observable pendingEmail = '';
  @observable username = '';
  @observable isSendingPhone = false;
  @observable isSendingCode = false;
  @observable openOnboard = false;

  constructor(snackbar) {
    this.snackbar = snackbar;
    this.isLoggedIn = !!localStorage.getItem('authToken');
    this.email = localStorage.getItem('email');
    this.username = localStorage.getItem('username');
  }

  /**
   *  Email login, Sign Up
   */
  @action.bound loginWithMail(email, password) {
    return new Promise((resolve, reject) => {
      loginWithEmail(email, password)
        .then(data => {
          getSCRealObservable();

          this.username = data.name || '';
          this.email = data.email || '';
          localStorage.setItem("authToken", data.jwt || "");
          localStorage.setItem("username", this.username);
          localStorage.setItem("email", this.email);

          this.showSnackMsg('You are successfully logged in.');
          this.isLoggedIn = true;
          resolve(true);
        })
        .catch(e => {
          console.log('[verify failed]', e);
          this.showSnackMsg('Login failed.');
          reject(e);
        });
    });
  }

  @action.bound checkExistEmail(email) {
    return new Promise((resolve, reject) => {
      existEmail(email)
        .then(data => {
          console.log('isExist:', data.isExist);
          if (data.isExist) {
            this.showSnackMsg('Email is existing already.');
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(e => {
          console.log('[checkExistEmail]', e);
          resolve(true);
        });
    });
  }

  @action.bound resetPasswordRequest(email) {
    resetPasswordRequest(email)
      .then(ret => {
        this.showSnackMsg(ret.message);
        setTimeout(() => {
          window.location.href = '/login';
        }, 3000);
      })
      .catch(err => {
        this.showSnackMsg(err.error);
      });
  }

  @action.bound resetPassword(token, password, tfaCode) {
    resetPassword(password, token, tfaCode)
      .then(ret => {
        this.showSnackMsg(ret.message);
        setTimeout(() => {
          window.location.href = '/login';
        }, 3000);
      })
      .catch(err => {
        this.showSnackMsg(err.message);
      });
  }

  @action.bound registerWithMail(email, password) {
    return new Promise((resolve, reject) => {
      registerWithEmail('', email, password)
        .then(data => {
          this.showSnackMsg(data.message);
          resolve(true);
        })
        .catch(e => {
          this.showSnackMsg(e.message);
          reject(e);
        });
    });
  }

  @action.bound confirmMail(email, token) {
    return new Promise((resolve, reject) => {
      confirmEmail(email, token)
        .then(data => {
          getSCRealObservable();
          this.username = data.name || '';
          this.email = data.email || '';
          this.openOnboard = true;

          localStorage.setItem("authToken", data.jwt || "");
          localStorage.setItem("username", this.username);
          localStorage.setItem("email", this.email);

          this.showSnackMsg(data.message);
          this.isLoggedIn = true;
          resolve(true);
        })
        .catch(e => {
          this.showSnackMsg(e.message);
          reject(e);
        });
    });
  }

  @action.bound setPendingEmail(email) {
    this.pendingEmail = email;
  }

  @action.bound setOpenOnboard(mode) {
    this.openOnboard = mode;
  }

  /**
   *  Logout
   */
  @action.bound logout() {
    this.isLoggedIn = false;
    this.email = '';
    this.username = '';
    localStorage.clear();
    window.location.href = '/login';
  }

  /**
   *  Snackbar Popup message
   */
  @action.bound showSnackMsg(msg) {
    this.snackbar({
      message: () => (
        <>
          <span>
            <b>{msg}</b>
          </span>
        </>
      )
    });
  }
}

export default (snackbar) => new AuthStore(snackbar);
