import React from 'react';
import { action, observable, reaction } from 'mobx';

import {
  HistoryRequest
} from '@/lib/ws';

class HistoryStore {
  @observable txHistoryData = [];
  isLoggedIn = false;

  constructor(authStore, snackbar) {
    this.isLoggedIn = authStore.isLoggedIn;
    this.snackbar = snackbar;

    if (this.isLoggedIn) {
      this.getTxHistory();
    }

    reaction(
      () => ({
        isLoggedIn: authStore.isLoggedIn
      }),
      authObj => {
        this.isLoggedIn = authObj.isLoggedIn;
        console.log("authObj.isLoggedIn:", authObj.isLoggedIn);
        if (this.isLoggedIn) {
          this.getTxHistory();
        }
      }
    );
  }

  @action.bound getTxHistory() {
    HistoryRequest({})
      .then(data => {
        if (!data.error) {
          try {
            const txData = data.data.result.actions || [];
            this.txHistoryData = txData;
            console.log('this.txHistoryData:', this.txHistoryData);
          } catch (e) {
            this.txHistoryData = [];
          }
        } else {
          this.txHistoryData = [];
        }
      })
      .catch(err => {
        console.log('getTxHistory: ', err);
        this.txHistoryData = [];
      });
  }

  @action.bound showSnackMsg(msg) {
    this.snackbar({
      message: () => (
        <>
          <span>
            <b>{msg}</b>
          </span>
        </>
      )
    });
  }
}

export default (authStore, snackbar) => {
  const store = new HistoryStore(authStore, snackbar);
  return store;
};
