import React, { Component } from 'react';
import Switch from '@material-ui/core/Switch';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import FrameBox from '@/components/FrameBox';
import Export from './export';
import UpdatePass from './updatePass';
import { Wrapper, Label } from './styles';
import { STORE_KEYS } from '@/stores';

class Security extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputCode: '',
    };
  }

  componentDidMount() {
    const { getSecurityRequest } = this.props;
    getSecurityRequest();
  }

  onChangeInputCode = (e) => {
    this.setState({
      inputCode: e.target.value || '',
    });
  };

  submitCode = () => {
    const { inputCode } = this.state;
    const { verify2FARequest } = this.props;
    verify2FARequest(inputCode);
  };

  render() {
    const { inputCode } = this.state;
    const {
      is_tfa, isTry2fa, handleChangeSwitch, tfaStr,
    } = this.props;

    return (
      <Wrapper>
        <FrameBox title="Two-Step Verification" isFull={true}>
          <div className="row form-group">
            <div className="col-md-4 form-label">
              Enable Two-Step Verification
            </div>
            <div className="col-md-8">
              <Switch
                checked={isTry2fa || is_tfa}
                onChange={handleChangeSwitch}
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-4 form-label">
            </div>
            <div className="col-md-8">
              <Label>
                Two-step verification helps to prevent unauthorized access to your wallet by requiring a one-time password for every login attempt.<br/>
                Enable this to further secure your wallet. Use an Authenticator app.
              </Label>
            </div>
          </div>
          {
            isTry2fa && !is_tfa && (
              <div className="row">
                <div className="col-md-4 form-label">
                  <img
                    src={tfaStr}
                    width={200}
                  />
                </div>
                <div className="col-md-8">
                  Scan this QR code with your Google Authenticator app on
                  your mobile phone (download for iOS or Android). Once
                  scanned you will be presented with a random 6 digit
                  number. Enter that number below to finish the setup
                  process.<br/><br/>
                  <input type={'text'} className="form-input" value={inputCode} onChange={this.onChangeInputCode}/><br/>
                  <button type="submit" className="btn-submit" onClick={this.submitCode}>Verify Code</button>
                </div>
              </div>
            )
          }
        </FrameBox>
        <Export/>
        <UpdatePass/>
      </Wrapper>
    );
  }
}

export default compose(
  inject(STORE_KEYS.SECURITYSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.SECURITYSTORE]: {
         is_tfa,
         isTry2fa,
         getSecurityRequest,
         handleChangeSwitch,
         tfaStr,
         verify2FARequest,
       },
     }) => ({
      is_tfa,
      isTry2fa,
      getSecurityRequest,
      handleChangeSwitch,
      tfaStr,
      verify2FARequest,
    })
  )
)(Security);
