import React from 'react';
import {
  ContentBox
} from './styles';

export default ({ title, isFull, isHeader = true, nopadding = false, children }) => (
  <ContentBox isFull={isFull} isHeader={isHeader} nopadding={nopadding}>
    <div className="card-header">{title}</div>
    <div className="card-body">
      {children}
    </div>
  </ContentBox>
);
