import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import { STORE_KEYS } from '@/stores';
import { Wrapper, Input, Button, LinkTo } from './styles';

class LoginEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      this.setRedirect();
    }
  }

  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value
    });
  };

  onChangePassword = (e) => {
    this.setState({
      password: e.target.value
    });
  };

  goLogin = () => {
    let { email, password } = this.state;
    const { showSnackMsg } = this.props;
    email = (email || '').trim();
    password = (password || '').trim();

    if (email.length === 0 || password.length === 0) {
      showSnackMsg('Email or Password is not valid.');
      return;
    }

    this.props.loginWithMail(email, password)
      .then(() => {
        this.setRedirect();
      })
      .catch(err => {
        showSnackMsg('Login credential is not correct.');
      });
  };

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/home' />
    }
  };

  render() {
    const { email, password } = this.state;

    return (
      <Wrapper>
        {this.renderRedirect()}
        <h3>Sign In Your pTrader Account</h3><br/>
        <p className="head_title">Enter your <span>email address</span> and <span>password</span>.</p>
        <Input type="text" placeholder="you@example.com" value={email} onChange={this.onChangeEmail}/>
        <Input type="password" placeholder="password" value={password} onChange={this.onChangePassword}/>
        <Button onClick={this.goLogin}>Sign In</Button>

        <p className="small_title">
          Trying to have an issue to login?<br/> <LinkTo href={"/reset"}>Forgot Password</LinkTo>
        </p>
      </Wrapper>
    );
  }
}

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: { loginWithMail, isLoggedIn, showSnackMsg },
     }) => ({
      loginWithMail,
      isLoggedIn,
      showSnackMsg,
    })
  )
)(LoginEmail);
