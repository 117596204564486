import React from "react";
import styled from "styled-components/macro";

const SettingsSvg = styled.svg`
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const SettingsIcon = props => (
  <SettingsSvg {...props} viewBox="0 0 16 16">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9.40146 16L9.81022 13.9562C10.6277 13.6642 11.3869 13.2555 12.0876 12.6715L14.0146 13.3139L15.5912 10.6277L14.0146 9.28467C14.2482 8.40876 14.2482 7.53285 14.0146 6.65693L15.5912 5.31387L14.0146 2.62774L12.0876 3.27007C11.3869 2.74453 10.6277 2.27737 9.81022 1.9854L9.40146 0H6.24818L5.83942 1.9854C4.9635 2.27737 4.20438 2.74453 3.56204 3.27007L1.57664 2.62774L0 5.31387L1.57664 6.65693C1.40146 7.53285 1.40146 8.40876 1.57664 9.28467L0 10.6277L1.57664 13.3139L3.56204 12.6715C4.20438 13.2555 4.9635 13.6642 5.83942 13.9562L6.24818 16H9.40146ZM10.2774 8C10.2774 9.34307 9.16788 10.4526 7.82482 10.4526C6.42336 10.4526 5.31387 9.34307 5.31387 8C5.31387 6.59854 6.42336 5.48905 7.82482 5.48905C9.16788 5.48905 10.2774 6.59854 10.2774 8Z"
          fill="#fff" fillOpacity="0.2"/>
  </SettingsSvg>
);

const PlusSvg = styled.svg`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const PlusIcon = props => (
  <PlusSvg {...props} viewBox="0 0 16 16">
    <path
      d="M8 0C3.58737 0 0 3.58737 0 8C0 12.4126 3.58737 16 8 16C12.4126 16 16 12.4126 16 8C16 3.58737 12.4126 0 8 0ZM8.97684 9.41474V11.2C8.97684 11.5032 8.72421 11.7558 8.42105 11.7558H7.57895C7.27579 11.7558 7.02316 11.5032 7.02316 11.2V9.41474C7.02316 9.17895 6.83789 8.99368 6.60211 8.99368H4.81684C4.51368 8.99368 4.26105 8.74105 4.26105 8.43789V7.59579C4.26105 7.29263 4.51368 7.04 4.81684 7.04H6.60211C6.83789 7.04 7.02316 6.85474 7.02316 6.61895V4.8C7.02316 4.49684 7.27579 4.24421 7.57895 4.24421H8.42105C8.72421 4.24421 8.97684 4.49684 8.97684 4.8V6.58526C8.97684 6.82105 9.16211 7.00632 9.39789 7.00632H11.1832C11.4863 7.00632 11.7389 7.25895 11.7389 7.56211V8.40421C11.7389 8.70737 11.4863 8.96 11.1832 8.96H9.39789C9.17895 8.97684 8.97684 9.17895 8.97684 9.41474Z"
      fill="#EC7868"/>
  </PlusSvg>
);

export const CheckIcon = ({ size = 16 }) => (
  <svg viewBox="0 0 16 16" width={`${size}px`} height={`${size}px`} fill="currentColor">
    <path
      d="M6.34061007,10.8092633 L2.96568542,7.43433867 C2.65326599,7.12191924 2.14673401,7.12191924 1.83431458,7.43433867 C1.52189514,7.7467581 1.52189514,8.25329009 1.83431458,8.56570952 L5.83431458,12.5657095 C6.16898647,12.9003814 6.71945595,12.8729264 7.01916584,12.5066143 L14.2191658,3.70661433 C14.4989479,3.36465848 14.4485461,2.86064032 14.1065902,2.58085826 C13.7646344,2.3010762 13.2606162,2.35147801 12.9808342,2.69343386 L6.34061007,10.8092633 Z"/>
  </svg>
);

const DotsSvg = styled.svg`
  width: 35px;
  margin-right: 24px;
`;

export const ThreeDots = props => (
  <DotsSvg {...props} viewBox="0 0 35 5">
    <circle cx="2.5" cy="2.5" r="2.5" fill="#EC7868"/>
    <circle cx="17.5" cy="2.5" r="2.5" fill="#EC7868"/>
    <circle cx="32.5" cy="2.5" r="2.5" fill="#EC7868"/>
  </DotsSvg>
);

const ControlSvg = styled.svg`
  width: 16px;
  height: 16px;
  margin-right: 12px;
`;

export const SvgSiteSettings = props => (
  <ControlSvg {...props} viewBox="0 0 16 16">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9.40146 16L9.81022 13.9562C10.6277 13.6642 11.3869 13.2555 12.0876 12.6715L14.0146 13.3139L15.5912 10.6277L14.0146 9.28467C14.2482 8.40876 14.2482 7.53285 14.0146 6.65693L15.5912 5.31387L14.0146 2.62774L12.0876 3.27007C11.3869 2.74453 10.6277 2.27737 9.81022 1.9854L9.40146 0H6.24818L5.83942 1.9854C4.9635 2.27737 4.20438 2.74453 3.56204 3.27007L1.57664 2.62774L0 5.31387L1.57664 6.65693C1.40146 7.53285 1.40146 8.40876 1.57664 9.28467L0 10.6277L1.57664 13.3139L3.56204 12.6715C4.20438 13.2555 4.9635 13.6642 5.83942 13.9562L6.24818 16H9.40146ZM10.2774 8C10.2774 9.34307 9.16788 10.4526 7.82482 10.4526C6.42336 10.4526 5.31387 9.34307 5.31387 8C5.31387 6.59854 6.42336 5.48905 7.82482 5.48905C9.16788 5.48905 10.2774 6.59854 10.2774 8Z"
          fill="#EC7868"/>
  </ControlSvg>
);

export const SvgSiteEvents = props => (
  <ControlSvg {...props} viewBox="0 0 16 16">
    <path
      d="M16 3.5C16 3.10218 15.842 2.72064 15.5607 2.43934C15.2794 2.15804 14.8978 2 14.5 2H13.5V1.25C13.5 0.918479 13.3683 0.600537 13.1339 0.366117C12.8995 0.131696 12.5815 0 12.25 0C11.9185 0 11.6005 0.131696 11.3661 0.366117C11.1317 0.600537 11 0.918479 11 1.25V2H5V1.25C5 0.918479 4.8683 0.600537 4.63388 0.366117C4.39946 0.131696 4.08152 0 3.75 0C3.41848 0 3.10054 0.131696 2.86612 0.366117C2.6317 0.600537 2.5 0.918479 2.5 1.25V2H1.5C1.10218 2 0.720644 2.15804 0.43934 2.43934C0.158035 2.72064 0 3.10218 0 3.5V14.5C0 14.8978 0.158035 15.2794 0.43934 15.5607C0.720644 15.842 1.10218 16 1.5 16H14.5C14.8978 16 15.2794 15.842 15.5607 15.5607C15.842 15.2794 16 14.8978 16 14.5V3.5ZM13 13H3V7H13V13Z"
      fill="#EC7868"/>
  </ControlSvg>
);

const SvgMenu = styled.svg`
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  height: 100%;
  fill: #fff;
  margin-left: 25px;
  margin-top: 20px;
  
  &:hover {
    fill: #0f0;
    cursor: pointer;
  }
`;

export const SvgMenuIcon = props => (
  <SvgMenu {...props} viewBox="0 0 24.75 24.75">
    <path d="M0,3.875c0-1.104,0.896-2,2-2h20.75c1.104,0,2,0.896,2,2s-0.896,2-2,2H2C0.896,5.875,0,4.979,0,3.875z M22.75,10.375H2
		c-1.104,0-2,0.896-2,2c0,1.104,0.896,2,2,2h20.75c1.104,0,2-0.896,2-2C24.75,11.271,23.855,10.375,22.75,10.375z M22.75,18.875H2
		c-1.104,0-2,0.896-2,2s0.896,2,2,2h20.75c1.104,0,2-0.896,2-2S23.855,18.875,22.75,18.875z"/>
  </SvgMenu>
);

const SvgBell = styled.svg`
  max-height: 20px;
  width: 100%;
  height: 100%;
  fill: #fff;
  margin-left: 25px;
  margin-top: 20px;
  
  &:hover {
    fill: #0f0;
    cursor: pointer;
  }
`;

export const SvgBellIcon = props => (
  <SvgBell {...props} viewBox="0 0 512 512">
    <path d="M444.335,401.119c-1.044-27.347-10.849-53.96-27.893-75.414c-7.759-9.768-13.783-20.713-17.908-32.538
			c-4.118-11.774-6.205-24.088-6.205-36.598v-21.794c0-63.077-43.061-116.279-101.327-131.773V35.009
			C291.002,15.704,275.298,0,255.993,0c-19.304,0-35.009,15.704-35.009,35.009v67.953c-23.009,6.073-44.112,18.131-61.395,35.407
			c-25.752,25.75-39.933,59.988-39.933,96.405v21.794c0,25.009-8.564,49.563-24.113,69.135
			c-9.01,11.343-16.004,24.051-20.788,37.763c-4.248,12.15-6.625,24.797-7.106,37.657c-10.355,4.649-17.589,15.05-17.589,27.118
			c0,16.385,13.329,29.714,29.714,29.714h113.878c4.36,30.51,30.651,54.044,62.348,54.044s57.988-23.534,62.348-54.044h113.877
			c16.385,0,29.714-13.329,29.714-29.714C461.937,416.17,454.697,405.765,444.335,401.119z M238.888,35.009
			c0-9.432,7.674-17.106,17.106-17.106c9.431,0,17.106,7.673,17.106,17.106v64.5c-5.605-0.704-11.313-1.071-17.106-1.071
			c-5.765,0-11.475,0.358-17.106,1.059V35.009z M91.657,369.369c4.121-11.818,10.145-22.763,17.903-32.53
			c18.055-22.727,27.998-51.235,27.998-80.271v-21.794c0-31.636,12.32-61.377,34.688-83.745
			c22.381-22.37,52.123-34.689,83.748-34.689c65.305,0,118.433,53.129,118.433,118.434v21.794c0,14.528,2.425,28.83,7.207,42.502
			c4.786,13.717,11.78,26.425,20.791,37.769c13.999,17.622,22.312,39.286,23.835,61.688H85.722
			C86.387,388.581,88.358,378.802,91.657,369.369z M255.998,494.097c-21.8,0-40.033-15.551-44.196-36.141h88.392
			C296.032,478.547,277.798,494.097,255.998,494.097z M432.222,440.053H79.772c-6.512,0-11.811-5.298-11.811-11.811
			c0-6.513,5.298-11.811,11.811-11.811h352.45c6.513,0,11.811,5.298,11.811,11.811C444.034,434.755,438.735,440.053,432.222,440.053
			z"/>
  </SvgBell>
);

const SvgMail = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);
`;

export const SvgMailIcon = props => (
  <SvgMail {...props} viewBox="0 0 490 490">
    <path d="M480,85H10C4.478,85,0,89.478,0,95v300c0,5.522,4.478,10,10,10h470c5.522,0,10-4.478,10-10V95
			C490,89.478,485.522,85,480,85z M443.676,105L245,223.36L46.324,105H443.676z M470,385H20V112.597l150.807,89.842l-107.014,84.72
			l12.414,15.682l112.986-89.447l50.688,30.197C241.459,244.53,243.229,245,245,245s3.541-0.47,5.118-1.409l50.688-30.197
			l112.986,89.447l12.414-15.682L319.193,202.44L470,112.597V385z"/>
  </SvgMail>
);


// Laravel Spark Theme
const SvgProfile = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);
`;

export const SvgProfileIcon = props => (
  <SvgProfile {...props} viewBox="0 0 20 20">
      <path d="M10 20C4.4772 20 0 15.5228 0 10S4.4772 0 10 0s10 4.4772 10 10-4.4772 10-10 10zm0-17C8.343 3 7
              4.343 7 6v2c0 1.657 1.343 3 3 3s3-1.343 3-3V6c0-1.657-1.343-3-3-3zM3.3472 14.4444C4.7822 16.5884 7.2262 18 10
              18c2.7737 0 5.2177-1.4116 6.6528-3.5556C14.6268 13.517 12.3738 13 10 13s-4.627.517-6.6528 1.4444z"/>
  </SvgProfile>
);

const SvgSecurity = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);
`;

export const SvgSecurityIcon = props => (
  <SvgSecurity {...props} viewBox="0 0 18 20">
    <path d="M3 8V6c0-3.3 2.7-6 6-6s6 2.7 6 6v2h1c1 0 2 1 2 2v8c0 1-1 2-2 2H2c-1 0-2-1-2-2v-8c0-1 1-2 2-2h1zm5
              6.7V17h2v-2.3c.6-.3 1-1 1-1.7 0-1-1-2-2-2s-2 1-2 2c0 .7.4 1.4 1 1.7zM6 6v2h6V6c0-1.7-1.3-3-3-3S6 4.3 6 6z "/>
  </SvgSecurity>
);

const SvgAPI = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);
`;

export const SvgAPIIcon = props => (
  <SvgSecurity {...props} viewBox="0 0 20 20">
    <path d="M20 14v4c0 1-1 2-2 2h-4v-2c0-1-1-2-2-2s-2 1-2 2v2H6c-1 0-2-1-2-2v-4H2c-1 0-2-1-2-2s1-2 2-2h2V6c0-1
              1-2 2-2h4V2c0-1 1-2 2-2s2 1 2 2v2h4c1 0 2 1 2 2v4h-2c-1 0-2 1-2 2s1 2 2 2h2z "/>
  </SvgSecurity>
);


const SvgSubscription = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);  
`;

export const SvgSubscriptionIcon = props => (
  <SvgSecurity {...props} viewBox="0 0 14 20">
      <path d="M7 3v2c-2.8 0-5 2.2-5 5 0 1.4.6 2.6 1.5 3.5L2 15c-1.2-1.3-2-3-2-5 0-4 3-7 7-7zm5 2c1.2 1.3 2 3
              2 5 0 4-3 7-7 7v-2c2.8 0 5-2.2 5-5 0-1.4-.6-2.6-1.5-3.5L12 5zM7 20l-4-4 4-4v8zM7 8V0l4 4-4 4z"/>
  </SvgSecurity>
);

const SvgPayment = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);  
`;

export const SvgPaymentIcon = props => (
  <SvgSecurity {...props} viewBox="0 0 20 16">
    <path d="M18 4V2H2v2h16zm0 4H2v6h16V8zM0 2c0-1 1-2 2-2h16c1 0 2 1 2 2v12c0 1-1 2-2 2H2c-1 0-2-1-2-2V2zm4
              8h4v2H4v-2z"/>
  </SvgSecurity>
);

const SvgInvoice = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);  
`;

export const SvgInvoiceIcon = props => (
  <SvgSecurity {...props} viewBox="0 0 20 20">
    <path d="M4 2h16l-3 9H4c-.6 0-1 .4-1 1s.5 1 1 1h13v2H4c-1.7 0-3-1.3-3-3s1.3-3 3-3h.3L3 5 2 2H0V0h3c.5 0
              1 .5 1 1v1zm1 18c-1 0-2-1-2-2s1-2 2-2 2 1 2 2-1 2-2 2zm10 0c-1 0-2-1-2-2s1-2 2-2 2 1 2 2-1 2-2 2z"/>
  </SvgSecurity>
);


const SvgWallet = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);  
`;

export const SvgWalletIcon = props => (
  <SvgWallet {...props} viewBox="0 0 60 60">
    <path d="M5.119,12.498c0.138,0,0.277-0.028,0.411-0.089c0.52-0.235,1.071-0.395,1.639-0.473c0.547-0.075,0.93-0.579,0.854-1.127
		c-0.075-0.547-0.569-0.936-1.127-0.854C6.138,10.06,5.4,10.272,4.706,10.587c-0.503,0.228-0.727,0.82-0.499,1.323
		C4.374,12.279,4.738,12.498,5.119,12.498z"/>
    <path d="M11.029,11.879h2c0.553,0,1-0.447,1-1s-0.447-1-1-1h-2c-0.553,0-1,0.447-1,1S10.477,11.879,11.029,11.879z"/>
    <path d="M42.942,5.879l-5.436-5.436c-0.43-0.43-1.18-0.43-1.609,0L33.851,2.49l-2.046-2.046c-0.43-0.43-1.18-0.43-1.609,0
		L24.76,5.879H8c-4.411,0-8,3.589-8,8v4c0-0.417,0.255-0.773,0.617-0.923c0.377,0.902,0.919,1.716,1.581,2.415
		c0.017,0.065,0.024,0.133,0.043,0.197c0.156,0.53-0.147,1.086-0.677,1.241c-0.095,0.028-0.189,0.041-0.282,0.041
		c-0.433,0-0.831-0.282-0.959-0.718C0.108,19.403,0,18.646,0,17.879v30c0-0.553,0.447-1,1-1s1,0.447,1,1
		c0,0.575,0.081,1.144,0.241,1.689c0.156,0.53-0.147,1.086-0.677,1.241c-0.095,0.028-0.189,0.041-0.282,0.041
		c-0.433,0-0.831-0.282-0.959-0.718C0.108,49.403,0,48.646,0,47.879v4.848c0,3.943,3.209,7.152,7.152,7.152H60v-5
		c0,0.553-0.447,1-1,1h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1v-6.5H47c-5.238,0-9.5-4.262-9.5-9.5
		s4.262-9.5,9.5-9.5h13v-4.5c0,0.553-0.447,1-1,1h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1v-3v-2v-14H42.942z
		 M51,53.879h2c0.553,0,1,0.447,1,1s-0.447,1-1,1h-2c-0.553,0-1-0.447-1-1S50.447,53.879,51,53.879z M45,53.879h2
		c0.553,0,1,0.447,1,1s-0.447,1-1,1h-2c-0.553,0-1-0.447-1-1S44.447,53.879,45,53.879z M39,53.879h2c0.553,0,1,0.447,1,1
		s-0.447,1-1,1h-2c-0.553,0-1-0.447-1-1S38.447,53.879,39,53.879z M36.702,2.467l3.412,3.412l14,14h-5.391L37.456,8.611
		c-0.203-0.197-0.471-0.306-0.754-0.306L36.23,8.329l-11.55,11.55H19.29L35.266,3.904l0,0L36.702,2.467z M31,2.467l1.437,1.437
		L16.462,19.879h-2.874l14-14L31,2.467z M8,7.879h14.76l-2.776,2.776c-0.104-0.441-0.482-0.776-0.955-0.776h-2c-0.553,0-1,0.447-1,1
		s0.447,1,1,1h1.73l-8,8H8c-2.603,0-4.818-1.669-5.647-3.991c0.157-0.446,0.353-0.876,0.611-1.27
		c0.303-0.462,0.173-1.082-0.29-1.384c-0.19-0.124-0.406-0.158-0.616-0.144C2.446,10.154,4.96,7.879,8,7.879z M6.001,54.664
		c-0.168,0.367-0.53,0.584-0.91,0.584c-0.139,0-0.28-0.029-0.415-0.091c-0.693-0.317-1.337-0.731-1.914-1.231
		c-0.417-0.362-0.462-0.994-0.101-1.411c0.363-0.417,0.994-0.462,1.411-0.101c0.433,0.376,0.916,0.687,1.436,0.925
		C6.01,53.568,6.23,54.162,6.001,54.664z M6.001,24.664c-0.168,0.367-0.53,0.584-0.91,0.584c-0.139,0-0.28-0.029-0.415-0.091
		c-0.693-0.317-1.337-0.731-1.914-1.231c-0.417-0.362-0.462-0.994-0.101-1.411c0.363-0.417,0.994-0.462,1.411-0.101
		c0.433,0.376,0.916,0.687,1.436,0.925C6.01,23.568,6.23,24.162,6.001,24.664z M11,55.879H9c-0.553,0-1-0.447-1-1s0.447-1,1-1h2
		c0.553,0,1,0.447,1,1S11.553,55.879,11,55.879z M11,25.879H9c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1
		S11.553,25.879,11,25.879z M17,55.879h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S17.553,55.879,17,55.879z
		 M17,25.879h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S17.553,25.879,17,25.879z M23,55.879h-2
		c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S23.553,55.879,23,55.879z M23,25.879h-2c-0.553,0-1-0.447-1-1
		s0.447-1,1-1h2c0.553,0,1,0.447,1,1S23.553,25.879,23,25.879z M29,55.879h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2
		c0.553,0,1,0.447,1,1S29.553,55.879,29,55.879z M29,25.879h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1
		S29.553,25.879,29,25.879z M35,55.879h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S35.553,55.879,35,55.879z
		 M35,25.879h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S35.553,25.879,35,25.879z M41,25.879h-2
		c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S41.553,25.879,41,25.879z M47,25.879h-2c-0.553,0-1-0.447-1-1
		s0.447-1,1-1h2c0.553,0,1,0.447,1,1S47.553,25.879,47,25.879z M53,25.879h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2
		c0.553,0,1,0.447,1,1S53.553,25.879,53,25.879z M56.942,19.879l-8-8H49c0.553,0,1-0.447,1-1s-0.447-1-1-1h-2
		c-0.017,0-0.031,0.009-0.048,0.01l-2.01-2.01H58v12H56.942z"/>
    <path d="M55,9.879h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2c0.553,0,1-0.447,1-1S55.553,9.879,55,9.879z"/>
    <path d="M40,38.879c0,3.859,3.141,7,7,7h13v-14H47C43.141,31.879,40,35.02,40,38.879z M52,38.879c0,2.206-1.794,4-4,4s-4-1.794-4-4
		s1.794-4,4-4S52,36.673,52,38.879z"/>
  </SvgWallet>
);

const SvgTransaction = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);  
`;

export const SvgTransactionIcon = props => (
  <SvgTransaction {...props} viewBox="0 0 490 490">
    <rect x="0.1" width="489.9" height="87.9"/>
    <path d="M0,112.4V490h490V112.4H0z M116.7,429.3H60.3v-64.9h56.4C116.7,364.4,116.7,429.3,116.7,429.3z M116.7,327.4H60.3v-64.9
			h56.4C116.7,262.5,116.7,327.4,116.7,327.4z M116.7,225.9H60.3v-65.3h56.4C116.7,160.6,116.7,225.9,116.7,225.9z M429.7,401.3
			h-245c-7,0-12.4-5.4-12.4-12.4s5.4-12.4,12.4-12.4h244.9c7,0,12.4,5.4,12.4,12.4S436.7,401.3,429.7,401.3z M429.7,307.6h-245
			c-7,0-12.4-5.4-12.4-12.4s5.4-12.4,12.4-12.4h244.9c7,0,12.4,5.4,12.4,12.4S436.7,307.6,429.7,307.6z M429.7,213.5h-245
			c-7,0-12.4-5.4-12.4-12.4s5.4-12.4,12.4-12.4h244.9c7,0,12.4,5.4,12.4,12.4S436.7,213.5,429.7,213.5z"/>
  </SvgTransaction>
);


const SvgActivity = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);  
`;

export const SvgActivityIcon = props => (
  <SvgActivity {...props} viewBox="0 0 554.883 554.883">
    <path d="M277.441,0C124.214,0,0,124.214,0,277.441c0,153.227,124.214,277.442,277.441,277.442
			c153.227,0,277.442-124.215,277.442-277.442C554.883,124.214,430.668,0,277.441,0z M277.441,506.941
			c-126.546,0-229.5-102.955-229.5-229.5c0-126.546,102.954-229.5,229.5-229.5c126.545,0,229.5,102.954,229.5,229.5
			C506.941,403.986,403.986,506.941,277.441,506.941z"/>
    <path d="M277.441,63.241c-118.3,0-214.2,95.9-214.2,214.2c0,118.299,95.9,214.2,214.2,214.2c118.299,0,214.2-95.9,214.2-214.2
			C491.641,159.141,395.74,63.241,277.441,63.241z M279.867,94.86c11.268,0,20.402,9.134,20.402,20.401
			c0,11.267-9.135,20.401-20.402,20.401c-11.267,0-20.397-9.134-20.397-20.401C259.47,103.994,268.601,94.86,279.867,94.86z
			 M117.495,298.035c-11.267,0-20.401-9.135-20.401-20.401c0-11.267,9.134-20.401,20.401-20.401
			c11.267,0,20.401,9.134,20.401,20.401C137.896,288.9,128.762,298.035,117.495,298.035z M277.441,457.98
			c-11.267,0-20.401-9.133-20.401-20.4s9.134-20.4,20.401-20.4c11.266,0,20.401,9.133,20.401,20.4S288.707,457.98,277.441,457.98z
			 M303.738,256.165l-0.043,0.016L261.07,373.721l0.058-101.825L205.95,167.045l97.567,88.917l0.242,0.042l-0.049,0.132
			L303.738,256.165z M439.814,295.607c-11.268,0-20.402-9.133-20.402-20.4c0-11.267,9.135-20.401,20.402-20.401
			c11.266,0,20.4,9.134,20.4,20.401C460.215,286.475,451.08,295.607,439.814,295.607z"/>
  </SvgActivity>
);

const SvgFacial = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);  
`;

export const SvgFacialIcon = props => (
  <SvgFacial {...props} viewBox="0 0 31.999 31.999">
    <circle cx="20.779" cy="17.145" r="1.388"/>
    <path d="M18.614,23.711c-0.03,0.037-0.795,0.928-2.562,0.928c-1.772,0-2.598-0.778-2.611-0.797c-0.133-0.133-0.351-0.133-0.481,0
			c-0.135,0.131-0.136,0.351-0.004,0.482c0.041,0.041,1.01,0.998,3.099,0.998c2.108,0,3.052-1.133,3.091-1.18
			c0.116-0.146,0.097-0.357-0.051-0.479C18.949,23.545,18.734,23.564,18.614,23.711z"/>
    <path d="M30.597,17.195c-0.242-2.236-1.175-2.706-1.91-2.706c-0.123,0-0.241,0.016-0.355,0.04c0.12-0.604,0.207-2.082,0.207-2.417
			C28.535,5.423,22.923,0,15.999,0C9.075,0,3.462,5.423,3.462,12.112c0,0.479-0.095,1.65-0.013,2.389
			c-0.046-0.003-0.089-0.012-0.135-0.012c-0.737,0-1.667,0.47-1.912,2.706c-0.362,3.312,1.75,4.469,2.545,4.781
			c0.523,1.611,3.671,10.021,11.975,10.022h0.08c8.355-0.055,11.521-8.418,12.048-10.021C28.845,21.666,30.96,20.512,30.597,17.195z
			 M13.694,8.541c0,0,0.816,0.838-0.378,1.438c1.065,0.641,2.513-1.438,2.513-1.438s0.32,1.493-0.662,2.005
			c1.708,1.025,3.139-2.005,3.139-2.005s4.688-0.26,7.331,4.391H6.474C9.093,9.396,13.694,8.541,13.694,8.541z M23.814,17.146
			c0,1.672-1.361,3.033-3.034,3.033c-1.674,0-3.035-1.359-3.035-3.033c0-1.673,1.361-3.035,3.035-3.035
			C22.452,14.111,23.814,15.472,23.814,17.146z M13.993,17.146c0,1.672-1.361,3.033-3.034,3.033c-1.673,0-3.034-1.359-3.034-3.033
			l0,0h1.646l0,0c0,0.766,0.621,1.387,1.388,1.387c0.769,0,1.389-0.621,1.389-1.387l0,0H13.993L13.993,17.146z M8.589,13.897
			c-0.225,0.164-0.428,0.351-0.611,0.557v-0.557H8.589z M10.958,14.111c1.564,0,2.842,1.194,3.002,2.719h-1.653
			c-0.144-0.615-0.689-1.074-1.35-1.074c-0.661,0-1.205,0.458-1.349,1.074H7.976v-0.197C8.221,15.205,9.46,14.111,10.958,14.111z
			 M14.463,15.188c-0.285-0.508-0.668-0.948-1.136-1.29h1.136V15.188z M27.52,21.08l-0.294,0.07l-0.08,0.291
			c-0.026,0.096-2.735,9.479-11.149,9.533h-0.073c-8.35-0.002-11.043-9.438-11.067-9.531l-0.08-0.293l-0.296-0.07
			c-0.1-0.023-2.404-0.613-2.061-3.773c0.073-0.668,0.287-1.792,0.896-1.792c0.33,0,0.708,0.262,0.821,0.359l0.807,0.698
			l0.139,0.572h1.844v0.002c0,2.223,1.812,4.031,4.034,4.031c1.435,0,2.688-0.756,3.401-1.886c0.099,0.23,0.232,0.369,0.385,0.369
			c0.3,0,0.543-0.562,0.543-1.26c0-0.5-0.127-0.92-0.309-1.125c0.002-0.045,0.014-0.088,0.014-0.136
			c0-0.475-0.098-0.922-0.248-1.345v-1.992h3.774c-1.069,0.728-1.773,1.951-1.773,3.335c0,0.074,0.019,0.146,0.022,0.22
			c-0.089,0.225-0.146,0.512-0.146,0.834c0,0.696,0.243,1.262,0.545,1.262c0.083,0,0.158-0.053,0.229-0.129
			c0.72,1.108,1.964,1.851,3.383,1.851c2.226,0,4.034-1.81,4.034-4.031c0-1.389-0.704-2.612-1.771-3.339h3.047
			c0.335,0.741,0.625,1.577,0.846,2.542l-0.033,0.359l0.962-0.835c0.087-0.076,0.48-0.359,0.823-0.359
			c0.604,0,0.818,1.125,0.894,1.792C29.92,20.438,27.613,21.057,27.52,21.08z"/>
  </SvgFacial>
);

const SvgVoice = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);  
`;

export const SvgVoiceIcon = props => (
  <SvgVoice {...props} viewBox="0 0 463.589 463.589">
    <path d="M389.678,183.513h-19.276v-19.337c0-8.522-6.897-15.42-15.421-15.42c-8.524,0-15.42,6.897-15.42,15.42v19.337h-23.794
		V83.971C315.766,37.588,278.177,0,231.794,0s-83.971,37.588-83.971,83.971v99.542h-23.824v-19.337
		c0-8.522-6.897-15.42-15.421-15.42c-8.524,0-15.421,6.897-15.421,15.42v19.337H73.912c-8.523,0-15.421,6.898-15.421,15.421
		c0,8.525,6.897,15.42,15.421,15.42h19.246v12.049c0,62.799,41.985,115.867,99.332,132.885v90.085
		c0,7.86,6.355,14.217,14.185,14.217h50.178c7.861,0,14.216-6.356,14.216-14.217v-90.085
		c57.345-16.988,99.332-70.086,99.332-132.885v-12.049h19.276c8.523,0,15.42-6.895,15.42-15.42
		C405.098,190.41,398.201,183.513,389.678,183.513z M339.56,226.402c0,59.424-48.341,107.795-107.766,107.795
		c-59.425,0-107.795-48.371-107.795-107.795v-12.049h23.824v4.368c0,46.384,37.588,83.972,83.971,83.972
		s83.971-37.588,83.971-83.972v-4.368h23.794V226.402z"/>
  </SvgVoice>
);

const SvgIris = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);  
`;

export const SvgIrisIcon = props => (
  <SvgIris {...props} viewBox="0 0 299.124 299.124">
    <path d="M296.655,142.104c-2.659-3.576-66.046-87.574-147.093-87.574c-81.048,0-144.434,83.998-147.093,87.574
		c-3.292,4.427-3.292,10.489,0,14.916c2.659,3.576,66.045,87.573,147.093,87.573c81.047,0,144.434-83.997,147.093-87.573
		C299.947,152.594,299.947,146.531,296.655,142.104z M149.562,199.616c-27.6,0-50.054-22.454-50.054-50.054
		c0-27.601,22.454-50.055,50.054-50.055c27.601,0,50.055,22.454,50.055,50.055C199.617,177.162,177.162,199.616,149.562,199.616z"/>
    <circle cx="149.562" cy="149.562" r="21.261"/>
  </SvgIris>
);

const SvgFinger = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);  
`;

export const SvgFingerIcon = props => (
  <SvgFinger {...props} viewBox="0 0 58.999 58.999">
    <path d="M57.195,31.901c-0.478-4.887-1.392-11.528-3.039-15.422C49.92,6.469,40.16,0,29.29,0c-3.587,0-7.085,0.701-10.396,2.083
		c-0.948,0.396-1.877,0.833-2.761,1.301c-1.279,0.677-2.575,1.546-3.963,2.657c-6.165,4.937-9.97,12.078-10.438,19.594
		c-0.28,4.494,0.367,9.423,1.942,14.707c0.374,1.021,2.152,6.219,6.52,10.87c-0.013-6.379-0.076-13.283-0.374-15.318
		c-0.159-1.082-0.455-2.169-0.744-3.189c-2.894-10.21,2.32-20.993,12.127-25.084c2.814-1.175,5.793-1.726,8.853-1.606
		c7.994,0.286,16.708,7.035,19.426,15.044c1.968,5.801,2.2,22.181,2.039,32.524c0.005-0.006,0.011-0.01,0.016-0.016
		C58.57,45.752,57.253,32.456,57.195,31.901z"/>
    <path d="M47.506,21.7C45.075,14.535,37.015,8.267,29.904,8.012c-2.753-0.092-5.464,0.392-8.012,1.454
		c-8.873,3.701-13.59,13.457-10.973,22.693c0.308,1.084,0.623,2.242,0.799,3.444c0.349,2.379,0.393,10.443,0.398,17.519
		c1.44,1.239,3.096,2.382,5,3.325c-0.001-2.946-0.002-6.267-0.004-6.452c-0.112-10.965-2.026-17.929-2.046-18
		c-1.822-6.732,0.415-13.679,5.565-17.295c0.451-0.318,1.076-0.208,1.393,0.244c0.317,0.451,0.208,1.075-0.244,1.393
		c-4.463,3.134-6.387,9.213-4.786,15.127c0.083,0.294,2.008,7.304,2.118,18.534c0.002,0.203,0.003,4.204,0.004,7.324
		c1.24,0.477,2.573,0.865,4,1.15c-0.018-11.806-0.647-26.385-1.39-28.64c-1.383-4.199,0.509-8.59,4.4-10.214
		C27.11,19.208,28.146,19,29.207,19c3.221,0,6.112,1.917,7.367,4.883c1.994,4.718,2.579,12.986,2.72,19.093
		c0.013,0.553-0.425,1.011-0.977,1.023c-0.008,0-0.017,0-0.023,0c-0.542,0-0.987-0.433-1-0.977
		c-0.136-5.934-0.69-13.934-2.558-18.353C33.791,22.438,31.622,21,29.207,21c-0.796,0-1.573,0.156-2.312,0.464
		c-2.917,1.217-4.323,4.546-3.27,7.743c0.914,2.78,1.483,18.882,1.49,29.591c1.042,0.125,2.132,0.193,3.269,0.201h1.879
		c0.51-19.9-1.992-31.632-2.019-31.722c-0.153-0.531,0.152-1.085,0.683-1.239c0.533-0.15,1.086,0.152,1.239,0.683
		c0.11,0.382,2.616,12.109,2.099,32.278h2.933h2.018c0.014-2.157-0.022-6.536-0.055-7.971c-0.027-1.187-0.052-2.284-0.047-3.036
		c0.004-0.55,0.451-0.993,1-0.993c0.002,0,0.005,0,0.007,0c0.553,0.004,0.997,0.454,0.993,1.007
		c-0.005,0.737,0.02,1.814,0.046,2.977c0.033,1.438,0.069,5.744,0.055,7.971c1.425-0.064,2.774-0.251,4.043-0.563
		c0.494-15.539,0.267-30.906-2.082-36.455C39.14,17.114,34.441,14,29.207,14c-0.941,0-1.879,0.102-2.786,0.302
		c-0.547,0.112-1.073-0.222-1.192-0.761c-0.119-0.54,0.222-1.073,0.761-1.192C27.038,12.117,28.12,12,29.207,12
		c6.039,0,11.461,3.594,13.813,9.155c2.472,5.84,2.747,20.736,2.259,36.615c1.509-0.571,2.882-1.349,4.124-2.32
		C49.621,45.512,49.504,27.586,47.506,21.7z"/>
  </SvgFinger>
);

const SvgBell2 = styled.svg`
  width: 18px;
  height: 20px;
  margin-right: .5rem!important;
  fill:rgb(154, 165, 172);  
`;

export const SvgBell2Icon = props => (
  <SvgBell2 {...props} viewBox="0 0 18 20">
    <defs>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="linearGradient-1">
        <stop stopColor="#86A0A6" offset="0%"/>
        <stop stopColor="#596A79" offset="100%"/>
      </linearGradient>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="header" transform="translate(-926.000000, -29.000000)" fillRule="nonzero" fill="url(#linearGradient-1)">
        <g id="Group-3">
          <path
            d="M929,37 C929,34.3773361 930.682712,32.1476907 933.027397,31.3318031 C933.009377,31.2238826 933,31.1130364 933,31 C933,29.8954305 933.895431,29 935,29 C936.104569,29 937,29.8954305 937,31 C937,31.1130364 936.990623,31.2238826 936.972603,31.3318031 C939.317288,32.1476907 941,34.3773361 941,37 L941,43 L944,45 L944,46 L926,46 L926,45 L929,43 L929,37 Z M937,47 C937,48.1045695 936.104569,49 935,49 C933.895431,49 933,48.1045695 933,47 L937,47 L937,47 Z"
            id="Combined-Shape"/>
        </g>
      </g>
    </g>
  </SvgBell2>
);

const SvgHost = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);  
`;

export const SvgHostIcon = props => (
  <SvgHost {...props} viewBox="0 0 60 60">
    <g>
      <path d="M53,41c0-3.859-3.14-7-7-7s-7,3.141-7,7v1h-4v18h22V42h-4V41z M41,41c0-2.757,2.243-5,5-5s5,2.243,5,5v1H41V41z M55,44v14 H37V44h2h14H55z"/>
      <path d="M45,54.858V56c0,0.553,0.448,1,1,1s1-0.447,1-1v-1.142c1.72-0.447,3-1.999,3-3.858c0-2.206-1.794-4-4-4s-4,1.794-4,4 C42,52.859,43.28,54.411,45,54.858z M46,49c1.103,0,2,0.897,2,2s-0.897,2-2,2s-2-0.897-2-2S44.897,49,46,49z"/>
      <path d="M0.835,21C0.314,21.74,0,22.635,0,23.608v11.783C0,36.365,0.314,37.26,0.835,38h35.626c1.277-4.052,5.07-7,9.539-7 s8.261,2.948,9.539,7h3.626C59.686,37.26,60,36.365,60,35.392V23.608c0-0.974-0.314-1.868-0.835-2.608H0.835z M10.5,34 C8.019,34,6,31.981,6,29.5S8.019,25,10.5,25s4.5,2.019,4.5,4.5S12.981,34,10.5,34z M34,29c-0.552,0-1-0.448-1-1s0.448-1,1-1 s1,0.448,1,1S34.552,29,34,29z M38,29c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S38.552,29,38,29z M42,29c-0.552,0-1-0.448-1-1 s0.448-1,1-1s1,0.448,1,1S42.552,29,42,29z M46,29c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S46.552,29,46,29z M50,29 c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S50.552,29,50,29z"/>
      <path d="M55.392,0H4.608C2.067,0,0,2.067,0,4.608v11.783C0,17.365,0.314,18.26,0.835,19h58.329C59.686,18.26,60,17.365,60,16.392 V4.608C60,2.067,57.933,0,55.392,0z M10.5,15C8.019,15,6,12.981,6,10.5S8.019,6,10.5,6S15,8.019,15,10.5S12.981,15,10.5,15z M34,10 c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S34.552,10,34,10z M36,13c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1 S36.552,13,36,13z M38,10c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S38.552,10,38,10z M40,13c-0.552,0-1-0.448-1-1s0.448-1,1-1 s1,0.448,1,1S40.552,13,40,13z M42,10c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S42.552,10,42,10z M44,13c-0.552,0-1-0.448-1-1 s0.448-1,1-1s1,0.448,1,1S44.552,13,44,13z M46,10c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S46.552,10,46,10z M48,13 c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S48.552,13,48,13z M50,10c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1 S50.552,10,50,10z M52,13c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S52.552,13,52,13z"/>
      <path d="M0,42.608v11.783C0,56.933,2.067,59,4.608,59H32V40H0.835C0.314,40.74,0,41.635,0,42.608z M10.5,44 c2.481,0,4.5,2.019,4.5,4.5S12.981,53,10.5,53S6,50.981,6,48.5S8.019,44,10.5,44z"/>
    </g>
  </SvgHost>
);

const SvgDomain = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);  
`;

export const SvgDomainIcon = props => (
  <SvgDomain {...props} viewBox="0 0 32 32">
    <g>
      <path d="M6.501,6.249c0.44,0.335,0.892,0.654,1.361,0.939C7.623,7.764,7.411,8.372,7.221,9h1.927 c0.11-0.322,0.215-0.649,0.34-0.955C10.381,8.454,11.312,8.766,12.267,9h7.471c0.967-0.235,1.912-0.554,2.812-0.972 c0.125,0.31,0.229,0.644,0.343,0.972h1.891c-0.189-0.629-0.4-1.235-0.641-1.812c0.471-0.285,0.924-0.604,1.36-0.939 c0.84,0.818,1.572,1.743,2.179,2.751h2.688c-2.604-5.318-8.057-9-14.368-9C9.689,0,4.238,3.682,1.635,9h2.686 C4.929,7.992,5.661,7.065,6.501,6.249z M24.109,5.073c-0.246,0.176-0.493,0.349-0.75,0.509c-0.319-0.587-0.666-1.144-1.041-1.646 C22.95,4.266,23.544,4.651,24.109,5.073z M21.794,6.422c-0.808,0.371-1.64,0.67-2.496,0.88c-0.239-1.728-0.584-3.396-1.075-4.672 C19.605,3.329,20.829,4.655,21.794,6.422z M15.82,2.379c0.061-0.001,0.12-0.008,0.182-0.008s0.121,0.007,0.182,0.008 c0.438,0.717,0.965,2.507,1.354,5.229c-0.509,0.06-1.021,0.098-1.535,0.098c-0.517,0-1.028-0.038-1.535-0.098 C14.855,4.886,15.382,3.096,15.82,2.379z M13.771,2.658c-0.485,1.272-0.827,2.927-1.065,4.645c-0.843-0.206-1.661-0.5-2.453-0.86 C11.214,4.692,12.421,3.366,13.771,2.658z M9.684,3.936C9.31,4.438,8.965,4.996,8.642,5.582C8.386,5.423,8.139,5.25,7.893,5.074 C8.459,4.651,9.052,4.266,9.684,3.936z"/>
      <path d="M25.503,25.752c-0.438-0.336-0.894-0.654-1.36-0.941c0.237-0.574,0.45-1.182,0.641-1.811h-1.891 c-0.109,0.328-0.216,0.66-0.341,0.971c-0.901-0.418-1.848-0.734-2.813-0.971h-7.47c-0.955,0.234-1.885,0.547-2.778,0.955 C9.364,23.648,9.26,23.32,9.149,23H7.223c0.189,0.629,0.401,1.236,0.64,1.812c-0.47,0.285-0.921,0.604-1.361,0.938 C5.663,24.934,4.931,24.008,4.325,23H1.638c2.603,5.316,8.054,9,14.366,9c6.312,0,11.764-3.684,14.367-9h-2.688 C27.075,24.008,26.343,24.934,25.503,25.752z M7.893,26.928c0.246-0.176,0.494-0.35,0.749-0.508 c0.323,0.586,0.668,1.143,1.042,1.645C9.052,27.734,8.459,27.35,7.893,26.928z M10.251,25.559c0.792-0.356,1.61-0.653,2.453-0.858 c0.238,1.719,0.58,3.368,1.065,4.645C12.421,28.635,11.214,27.307,10.251,25.559z M16.184,29.621 c-0.061,0.002-0.12,0.008-0.182,0.008s-0.121-0.006-0.182-0.008c-0.438-0.717-0.966-2.508-1.354-5.229 c0.507-0.06,1.019-0.099,1.535-0.099c0.517,0,1.028,0.039,1.536,0.099C17.146,27.113,16.622,28.904,16.184,29.621z M18.223,29.369 c0.491-1.275,0.836-2.943,1.075-4.672c0.856,0.211,1.688,0.51,2.496,0.881C20.829,27.346,19.605,28.672,18.223,29.369z  M22.318,28.064c0.375-0.504,0.722-1.062,1.041-1.646c0.257,0.16,0.504,0.334,0.75,0.51C23.544,27.35,22.95,27.734,22.318,28.064z"/>
      <path d="M4.795,19.18l0.637-2.236c0.169-0.596,0.299-1.183,0.416-1.977h0.026c0.13,0.78,0.247,1.354,0.403,1.977l0.598,2.236 h1.859l1.95-6.355H8.748l-0.546,2.521c-0.143,0.729-0.273,1.443-0.364,2.171H7.812c-0.13-0.729-0.299-1.441-0.468-2.158 l-0.637-2.534h-1.56l-0.676,2.612c-0.156,0.623-0.338,1.353-0.468,2.08H3.977c-0.104-0.729-0.234-1.431-0.364-2.094l-0.507-2.601 H1.09l1.846,6.357h1.859V19.18z"/>
      <path d="M18.314,15.344c-0.145,0.729-0.272,1.443-0.362,2.172h-0.027c-0.129-0.729-0.299-1.442-0.467-2.159l-0.64-2.534h-1.56 l-0.676,2.612c-0.156,0.624-0.338,1.353-0.468,2.081h-0.026c-0.104-0.729-0.234-1.432-0.364-2.095l-0.507-2.601h-2.015 l1.846,6.357h1.859l0.637-2.235c0.169-0.599,0.299-1.184,0.416-1.978h0.026c0.13,0.78,0.248,1.354,0.404,1.978l0.598,2.235h1.859 l1.947-6.357h-1.938L18.314,15.344z"/>
      <path d="M28.43,15.344c-0.144,0.729-0.273,1.443-0.363,2.172h-0.025c-0.129-0.729-0.3-1.442-0.469-2.159l-0.637-2.534h-1.562 l-0.677,2.612c-0.155,0.624-0.338,1.353-0.469,2.081h-0.024c-0.104-0.729-0.233-1.432-0.363-2.095l-0.508-2.601h-2.017 l1.849,6.357h1.856l0.64-2.235c0.168-0.599,0.299-1.184,0.416-1.978h0.024c0.129,0.78,0.246,1.354,0.402,1.978l0.598,2.235h1.859 l1.949-6.357h-1.938L28.43,15.344z"/>
    </g>
  </SvgDomain>
);

const SvgSearch = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  fill:rgb(154, 165, 172);  
`;

export const SvgSearchIcon = props => (
  <SvgSearch {...props} viewBox="0 0 31.398 31.398">
    <g>
      <path d="M30.671,27.162l-2.782-2.782c1.193-2.621,0.719-5.819-1.432-7.971c-2.771-2.771-7.277-2.771-10.051,0.001 c-2.771,2.77-2.771,7.277,0.003,10.047c2.149,2.152,5.349,2.626,7.97,1.433l2.782,2.782c0.972,0.969,2.541,0.969,3.51,0 c0.466-0.465,0.728-1.098,0.728-1.754C31.398,28.26,31.137,27.627,30.671,27.162z M24.684,24.686 c-1.791,1.791-4.711,1.791-6.502,0c-1.793-1.793-1.793-4.711,0-6.502c1.791-1.794,4.711-1.794,6.502,0 C26.475,19.973,26.479,22.893,24.684,24.686z"/>
      <path d="M15.692,27.174c-0.6-0.6-1.09-1.277-1.47-2.01v2.656c-1.146-0.561-2.207-1.799-3.058-3.565 c0.797-0.24,1.634-0.396,2.485-0.503c-0.22-0.742-0.336-1.52-0.336-2.316c0-0.232,0.015-0.464,0.033-0.695 c-1.116,0.133-2.192,0.356-3.215,0.681c-0.37-1.354-0.605-2.806-0.701-4.317h4.791v0.598c0.379-0.73,0.868-1.408,1.467-2.006 c0.678-0.677,1.458-1.212,2.302-1.606h-1.022v-3.553c1.435-0.1,2.804-0.358,4.091-0.765c0.308,1.125,0.496,2.324,0.615,3.56 c0.979,0.028,1.93,0.224,2.812,0.581c-0.105-1.843-0.385-3.598-0.832-5.223c0.632-0.34,1.23-0.725,1.785-1.148 c1.612,1.883,2.608,4.131,2.902,6.548h-3.469c0.844,0.395,1.623,0.929,2.3,1.605c0.429,0.429,0.783,0.908,1.099,1.41h0.07 c-0.005,0.028-0.011,0.059-0.015,0.088c1.022,1.677,1.409,3.674,1.083,5.609c1.129-2.156,1.777-4.605,1.777-7.205 C31.188,6.996,24.191,0,15.595,0C6.995,0,0,6.996,0,15.596s6.995,15.596,15.595,15.596c2.584,0,5.018-0.644,7.167-1.761 c-0.443,0.074-0.894,0.113-1.342,0.113C19.253,29.543,17.219,28.701,15.692,27.174z M23.223,5.481 c-0.195,0.125-0.391,0.244-0.589,0.356c-0.172-0.377-0.352-0.739-0.538-1.084C22.484,4.977,22.857,5.22,23.223,5.481z  M16.969,3.367c1.143,0.559,2.204,1.796,3.055,3.564c-0.977,0.293-1.998,0.489-3.055,0.579V3.367z M14.223,3.365v4.147 c-1.059-0.09-2.081-0.283-3.058-0.578C12.016,5.163,13.077,3.926,14.223,3.365z M10.13,9.769c1.291,0.406,2.664,0.663,4.093,0.766 v3.553H9.432C9.527,12.577,9.763,11.128,10.13,9.769z M5.746,23.646c-1.608-1.884-2.604-4.129-2.898-6.545h3.838 c0.103,1.912,0.384,3.726,0.847,5.396C6.901,22.837,6.302,23.223,5.746,23.646z M6.686,14.087H2.848 c0.295-2.414,1.287-4.658,2.895-6.538C6.288,7.966,6.887,8.35,7.532,8.694C7.069,10.365,6.785,12.175,6.686,14.087z M7.969,25.708 c0.196-0.126,0.391-0.246,0.59-0.356c0.171,0.377,0.351,0.739,0.539,1.086C8.707,26.214,8.33,25.969,7.969,25.708z M8.562,5.833 C8.35,5.718,8.155,5.601,7.969,5.481C8.33,5.22,8.707,4.977,9.098,4.753C8.909,5.096,8.73,5.458,8.562,5.833z"/>
    </g>
  </SvgSearch>
);

