import React, { Component } from 'react';
import FrameBox from '@/components/FrameBox';
import ExchangeItem from "./ExchangItem";

class Exchanges extends Component {
  render() {
    const exchanges = [
      {
        name: 'ViteX',
        symbol: 'ViteX, Exchange By the Community, For the Community',
        url: 'https://x.vite.net/trade?symbol=PEG-000_BTC-000',
        icon: 'ex_vitex.jpeg',
      },
      {
        name: 'VineX',
        symbol: 'Vinex.network: Cryptocurrency Exchange',
        url: 'https://vinex.network/trading/advanced/BTC_PEG',
        icon: 'ex_vinx.png',
      },
      {
        name: 'QTrade',
        symbol: 'qTrade Digital Asset Exchange',
        url: 'https://qtrade.io/market/ETH_pUSD',
        icon: 'ex_qtrade.jpeg',
      },
      {
        name: 'Bilaxy',
        symbol: 'BILAXY - The World\'s Leading Crypto Asset Trading Platform',
        url: 'https://bilaxy.com/trade/PEG_USDT',
        icon: 'ex_bilaxy.png',
      },
      {
        name: 'Hotbit',
        symbol: 'One of the professional digital asset exchange platforms',
        url: 'https://www.hotbit.io/exchange?symbol=PEG_USDT',
        icon: 'ex_hotbit.png',
      },
      {
        name: 'IDEX',
        symbol: 'IDEX - Decentralized Ethereum Asset Exchange',
        url: 'https://idex.market/eth/peg',
        icon: 'ex_idex.png',
      },
      {
        name: 'UniSwap',
        symbol: 'Uniswap Exchange',
        url: 'https://youtu.be/wqxcHdES9hc',
        icon: 'ex_uniswap.jpg',
      },
      {
        name: 'Smart Valor',
        symbol: 'Smart Valor Exchange',
        url: 'https://smartvalor.com/',
        icon: 'ex_smart_valor.png',
      }
    ];

    return (
      <FrameBox title="Exchanges" isFull={true} nopadding={true}>
        {
          exchanges.map((item, index) => (
            <ExchangeItem name={item.name} icon={item.icon} symbol={item.symbol} url={item.url} key={index}/>
          ))
        }
      </FrameBox>
    );
  }
}

export default Exchanges;
