import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #edf0f2;
`;

export const Content = styled.div`
  width: 100%;
  padding: 1.5rem 16px !important;
  
  overflow-y: auto;
  height: calc(100% - 63px);
  
  > div {
    width: 100%;
    height: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
`;
