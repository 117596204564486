import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 1.25rem 1.5rem;
  
  .pdLeft {
    padding-left: 16px;
  }
  
  > ul {
    list-style: none;
    padding-left: 0;
  }
`;
