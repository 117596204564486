import React, { Component } from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { inject, observer } from 'mobx-react';
import { compose, withProps } from 'recompose';
import { Tooltip } from 'react-tippy';

import { List, StyleWrapper, HeaderWrapper, Item } from '../styles';
import { Wrapper } from './styles';
import { STORE_KEYS } from '@/stores';

const headerRenderer = coin => () => {
  return <HeaderWrapper>{coin}</HeaderWrapper>;
};

class BidsTable extends Component {
  symbolCellRenderer = ({ rowData }) => {
    return <Item>{`${rowData.symbol01}-${rowData.symbol02}`}</Item>;
  };

  exchangeCellRenderer = ({ rowData }) => {
    return <Item>{rowData.exchange}</Item>;
  };

  priceCellRenderer = ({ rowData }) => {
    return <Item>{rowData.price}</Item>;
  };

  quantityCellRenderer = ({ rowData }) => {
    return <Item>{`${rowData.quantity}`}</Item>;
  };

  amountCellRenderer = ({ rowData }) => {
    return <Item>{`${rowData.amount}`}</Item>;
  };

  render() {
    const data = this.props.bids;
    return (
      <Wrapper>
        <div className="label">Bids (Buys) {(data.length)}</div>
        <List>
          <AutoSizer>
            {({ width, height }) => {
              return (
                <StyleWrapper width={width} height={height}>
                  <PerfectScrollbar
                    options={{
                      suppressScrollX: true,
                      minScrollbarLength: 50
                    }}
                  >
                    <Table
                      width={width}
                      height={height}
                      headerHeight={32}
                      disableHeader={false}
                      rowCount={data.length}
                      rowGetter={({ index }) => data[index]}
                      rowHeight={27}
                      overscanRowCount={0}
                    >
                      <Column
                        headerStyle={{ margin: 0 }}
                        dataKey="Side"
                        headerRenderer={headerRenderer('Price')}
                        cellRenderer={this.priceCellRenderer}
                      />
                      <Column
                        headerStyle={{ margin: 0 }}
                        dataKey="Side"
                        headerRenderer={headerRenderer('Quantity')}
                        cellRenderer={this.quantityCellRenderer}
                      />
                      <Column
                        headerStyle={{ margin: 0 }}
                        dataKey="Side"
                        headerRenderer={headerRenderer('Amount')}
                        cellRenderer={this.amountCellRenderer}
                      />
                      <Column
                        headerStyle={{ margin: 0 }}
                        dataKey="Symbol"
                        headerRenderer={headerRenderer('Symbol')}
                        cellRenderer={this.symbolCellRenderer}
                      />
                      <Column
                        headerStyle={{ margin: 0 }}
                        dataKey="Exchange"
                        headerRenderer={headerRenderer('Exchange')}
                        cellRenderer={this.exchangeCellRenderer}
                      />
                    </Table>
                  </PerfectScrollbar>
                </StyleWrapper>
              );
            }}
          </AutoSizer>
        </List>
      </Wrapper>
    );
  }
}

export default compose(
  inject(STORE_KEYS.ORDERBOOKSTORE),
  observer,
  withProps(({ [STORE_KEYS.ORDERBOOKSTORE]: { bids } }) => ({ bids }))
)(BidsTable);
