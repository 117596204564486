import React, { Component } from 'react';
import PriceChartCanvas from "./PriceChartCanvas";

import { Wrapper, ChartWrapper, OrderBookWrapper } from './styles';
import OrderBook from "./OrderBook";

class Trade extends Component {
  render() {
    return (
      <Wrapper>
        <ChartWrapper>
          <PriceChartCanvas/>
        </ChartWrapper>
        <OrderBookWrapper>
          <OrderBook/>
        </OrderBookWrapper>
      </Wrapper>
    );
  }
}

export default Trade;
