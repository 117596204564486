import React from 'react';
import {
  createMuiTheme, makeStyles, ThemeProvider
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Contents from "./Contents";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: '12px',
    top: '15px',
    color: 'rgb(0, 206, 255)',
    zIndex: '100',
  },
  addButton: {
    position: 'absolute',
    background: 'transparent',
    width: 'calc(100% - 20px)',
    height: '100%',
    borderRadius: '0',
  },
  dlgContent: {
    padding: '15px',
  },
}));

function MaxWidthDialog({ symbol, name, icon, dlgMode, setDlgMode }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setDlgMode('');
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div onClick={handleClickOpen} className={classes.addButton}/>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open || (dlgMode !== '')}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dlgContent}>
          <Contents
            symbol={symbol} name={name} icon={icon} dlgMode={dlgMode}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(73,84,90)',
    },
    secondary: {
      main: '#ff9100',
    },
  },
});

export default function CustomStyles({ symbol, name, icon, dlgMode, setDlgMode }) {
  return (
    <ThemeProvider theme={theme}>
      <MaxWidthDialog symbol={symbol} name={name} icon={icon} dlgMode={dlgMode} setDlgMode={setDlgMode}/>
    </ThemeProvider>
  );
}
