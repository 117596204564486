import { BehaviorSubject, empty } from 'rxjs';

import { createSCConnection, getOpenConnection, destroySocket } from '@/lib/ws/mm-connection';
import { WS } from '@/config/constants';

let scRealConnection;
let scRealOpenObservable;
export const realNetworkObservable = new BehaviorSubject(false);

let realSocket = null;

export const getSCRealObservable = () => {
  // todo get rid of this;
  const isLoggedIn = !!localStorage.getItem('authToken');
  if (!isLoggedIn) {
    scRealConnection = null;
    scRealOpenObservable = empty();

    return scRealOpenObservable;
  }

  if (scRealConnection) {
    return scRealOpenObservable;
  }

  scRealConnection = createSCConnection({
    port: WS.REAL.PORT,
    hostname: WS.REAL.HOST,
    autoReconnect: true
  });

  scRealOpenObservable = getOpenConnection(scRealConnection);

  scRealConnection.subscribe(({ socket, connected }) => {
    realSocket = socket;
    realNetworkObservable.next(connected);
  });

  return scRealOpenObservable;
};

export const destroyRConnection = () => {
  scRealConnection = null;
  scRealOpenObservable = empty();
  destroySocket(realSocket);
};

export const DepositAddressRequest = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('DepositAddressRequest', payload);
      cli.once('DepositAddressResponse', data => {
        resolve(data);
      });
    });
  });
};

export const WithdrawCoinRequest = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('WithdrawCoinRequest', payload);
      cli.once('WithdrawCoinResponse', data => {
        resolve(data);
      });
    });
  });
};

export const ConversionRequest = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('ConversionRequest', payload);
      cli.once('ConversionResponse', data => {
        resolve(data);
      });
    });
  });
};

export const HistoryRequest = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('HistoryRequest', payload);
      cli.once('HistoryResponse', data => {
        resolve(data);
      });
    });
  });
};

export const RealWithdrawConfirm = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('RealWithdrawConfirm', payload);
      cli.once('RealWithdrawConfirmResponse', data => {
        resolve(data);
      });
    });
  });
};

export const GetSecurityRequest = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('GetSecurityRequest', payload);
      cli.once('GetSecurityResponse', data => {
        resolve(data);
      });
    });
  });
};

export const Verify2FARequest = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('Verify2FARequest', payload);
      cli.once('Verify2FAResponse', data => {
        resolve(data);
      });
    });
  });
};

export const Enable2FARequest = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('Enable2FARequest', payload);
      cli.once('Enable2FAResponse', data => {
        resolve(data);
      });
    });
  });
};

export const Disable2FARequest = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('Disable2FARequest', payload);
      cli.once('Disable2FAResponse', data => {
        resolve(data);
      });
    });
  });
};

export const ExportAccountRequest = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('ExportAccountRequest', payload);
      cli.once('ExportAccountResponse', data => {
        resolve(data);
      });
    });
  });
};

export const VerifyPassRequest = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('VerifyPassRequest', payload);
      cli.once('VerifyPassResponse', data => {
        resolve(data);
      });
    });
  });
};

export const AccountSettingsRequest = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('AccountSettingsRequest', payload);
      cli.once('AccountSettingsResponse', data => {
        resolve(data);
      });
    });
  });
};

export const ChangeCoinRequest = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('ChangeCoinRequest', payload);
      cli.once('ChangeCoinResponse', data => {
        resolve(data);
      });
    });
  });
};

export const ApiTradeRequest = payload => {
  return new Promise(resolve => {
    getSCRealObservable().subscribe(cli => {
      cli.emit('ApiTradeRequest', payload);
      cli.once('ApiTradeResponse', data => {
        resolve(data);
      });
    });
  });
};
